<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >南京秦淮财税金融服务协会、南京财商人生教育科技有限公司携手为南京财经大学毕业生提供就业指导服务</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-10-17</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            南京秦淮财税金融服务协会、南京财商人生教育科技有限公司携手为南京财经大学毕业生提供就业指导服务
          </div>
          <div class="titname">FOUND</div>
          <p>
            10月13日下午，为促进高校毕业生等重点群体高质量充分就业，南京市劳动就业服务管理中心联合南京电视台大学生频道共同策划的“宁聚服务行
            乐业金陵”青春就业直通车——2023年毕业生就业指导进校园活动走进了南京财经大学。南京秦淮财税金融服务协会和南京财商人生教育科技有限公司继续受邀为大学生提供现场就业指导、简历修改等服务。
          </p>
          <div><img src="../../../assets/images/newTen1.png" alt="" /></div>

          <p>
            此次进校指导活动联动242家用人单位参与，为南京财经大学2024届毕业生带来共计1039个岗位，总计招聘15316人。涉及制造业、金融业、软件和信息技术服务业、批发和零售业、商务服务业等。
          </p>

          <div><img src="../../../assets/images/newTen2.png" alt="" /></div>

          <p>
            招聘会开始没多久，就业指导区域便排起了长队，财商人生公司陈宣妍等3位老师对每位前来咨询的学生都一对一给予认真详尽的解答，在简历设计、模版选用、如何突出自己的核心竞争力等方面给同学们提出修改意见和建议，有些同学还咨询了诸如个人兴趣、职业规划方向与专业不一致该如何解决、考研考公就业该如何抉择、未来职业如何选择等方面的问题，老师们都给予专业指导，同学们纷纷表示听了老师的建议，受益匪浅，收获良多。
          </p>

          <div><img src="../../../assets/images/newTen3.png" alt="" /></div>

          <div><img src="../../../assets/images/newTen4.png" alt="" /></div>
          <div><img src="../../../assets/images/newTen5.png" alt="" /></div>

          <p>
            南京秦淮财税金融服务协会联合南京财商人生教育科技有限公司及相关会计师事务所共同发起成立的注册会计师（CPA）服务联盟，其工作主旨之一是全方位赋能大学生就业，帮助大学生精准把握行业需求，规划职业发展路径，提升就业核心竞争力。为财税法金融相关专业大学生提供职业规划辅导、简历制作指导与修改、面试技巧辅导、简历代投、实习/就业推荐等专业服务，联合社会多方力量，多措并举推动大学生高质量就业。
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>