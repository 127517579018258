<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >履职尽责献良策 参政议政助发展</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2024-01-22</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
           履职尽责献良策 参政议政助发展
          </div>
					<p style="text-align: center;">——我会方维翔会长参加北京市西城区政协十五届三次会议和南京市政协十五届二次会议</p>
          <div class="titname">FOUND</div>
          <p>
            2024年1月区市级两会期间，我会方维翔会长奔赴北京、南京两地分别参加北京市西城区政协十五届三次会议和南京市政协十五届二次会议。
          </p>
          <div><img src="../../../assets/images/index/640 (1).jpeg" alt="" /><p style="text-align: center;">2024.1.8-1.11北京市西城区政协十五届三次会议期间</p></div>
          <div>
						<img src="../../../assets/images/index/640 (1).png" alt="" />
						<p style="text-align: center;">2024.1.15-1.18南京市政协十五届二次会议期间</p>
					</div>

          <p>
            2023年11月中旬，方会长就开始走访调研相关企业，精心准备提案，紧紧围绕新时代大国高质量发展的金融强国与人才强国两大国策建言献策，准备了《提升西城区金融服务能力，做好科创金融大文章》、《关于加大高层次人才引进培育力度的建议》两份提案分别向北京市西城区政协十五届三次会议和南京市政协十五届二次会议提交。
          </p>
          <p>
            方会长说，作为北京市西城区政协委员和南京市政协委员，他将认真履行职责，关注国家经济发展和社会动态，积极参政议政，主动作为，以饱满的热情和务实的行动，为北京市西城区和南京市的金融服务事业和高层次人才培养、引进、使用方面发挥专业优势，积极建言献策，贡献自己绵薄之力。
          </p>
          
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>