<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }"
          >协会动态</el-breadcrumb-item
        >
        <el-breadcrumb-item>协会新闻</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-main">
      <!-- <el-scrollbar class="page-scroll"> -->
        <div class="main-item" @click="toFiveteen">
          <span
            >【喜讯】南京秦淮财税金融服务协会荣获“AAA等级社会组织”荣誉称号</span
          ><span>2024-01-25</span>
        </div>
        <div class="main-item" @click="toFourteen">
          <span
            >履职尽责献良策 参政议政助发展</span
          ><span>2024-01-22</span>
        </div>
        <div class="main-item" @click="toThirteen">
          <span
            >CPA执业圈财总分享会“业财融合（医药行业）”主题活动成功举行</span
          ><span>2023-11-01</span>
        </div>
        <div class="main-item" @click="toTwelve">
          <span
            >南京秦淮财税金融服务协会方维翔会长、武孝奎秘书长应邀赴江苏省温州商会参观交流</span
          ><span>2023-10-25</span>
        </div>
        <div class="main-item" @click="toEleven">
          <span
            >秦淮财税金融服务协会、财商人生就业指导进高校，为学子提供就业助力</span
          ><span>2023-10-24</span>
        </div>
        <div class="main-item" @click="toTen">
          <span
            >南京秦淮财税金融服务协会、南京财商人生教育科技有限公司携手为南京财经大学毕业生提供就业指导服务</span
          ><span>2023-10-17</span>
        </div>
        <div class="main-item" @click="toNine">
          <span
            >聚力赋能，助推梦想启航——记秦淮财税协会、财商人生携手为南审大毕业生提供就业指导服务</span
          ><span>2023-09-22</span>
        </div>
        <div class="main-item" @click="toInt">
          <span>关于会费标准的公告</span><span>2023-07-03</span>
        </div>
        <div class="main-item" @click="toSeven">
          <span>关于同意成立“南京秦淮财税金融服务协会人才开发研究院”的决议</span
          ><span>2023-06-13</span>
        </div>
        <div class="main-item" @click="toSex">
          <span
            >大学生实现高质量就业公益巡回讲座第三站——南工大专场圆满落幕！</span
          ><span>2023-06-07</span>
        </div>
        <div class="main-item" @click="toFive">
          <span
            >财经金融专业大学生实现高质量就业公益巡回讲座（南农大专场）成功举办</span
          ><span>2023-06-06</span>
        </div>
        <div class="main-item" @click="toFour">
          <span
            >热烈祝贺财经专业大学生实现高质量就业公益巡回讲座（南财专场）取得圆满成功！</span
          ><span>2023-06-05</span>
        </div>
        <div class="main-item" @click="toThree">
          <span>协会委托财商人生开展财经公益培训</span><span>2021-10-18</span>
        </div>
        <div class="main-item" @click="toTwo">
          <span>2019年秦淮区重点企业暨中小企业培训会议召开</span
          ><span>2019-12-20</span>
        </div>
        <div class="main-item" @click="toOne">
          <span>南京秦淮财税金融服务协会正式成立</span
          ><span>2019-06-30</span>
        </div>
      <!-- </el-scrollbar> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toFiveteen(){
      this.$router.push("/newFiveteen");
    },
    toFourteen() {
      this.$router.push("/newFourteen");
    },
    toThirteen(){
      this.$router.push("/newThirteen");

    },
     toTwelve() {
      this.$router.push("/newTwelve");
    },
    toEleven(){
      this.$router.push("/newEleven");

    },
    toTen() {
      this.$router.push("/newTen");
    },
    toNine() {
      this.$router.push("/newNine");
    },
    toInt() {
      this.$router.push("/newEnghit");
    },
    toTwo() {
      this.$router.push("/newTwo");
    },
    toOne(){
      this.$router.push("/newOne");
    },
    toThree() {
      this.$router.push("/newThree");
    },
    toFour() {
      this.$router.push("/newFour");
    },
    toFive() {
      this.$router.push("/newFive");
    },
    toSex() {
      this.$router.push("/newSex");
    },
    toSeven() {
      this.$router.push("/newSeven");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
  .brief-main {
    // height: 540px;
    // overflow: hidden;
    // /deep/.page-scroll {
    //       width: 100%;

    //   height: 100%;
    //   .el-scrollbar__wrap {
    //   overflow-x: hidden;
    // }
    // }
  }
}
</style>