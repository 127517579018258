<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >大学生实现高质量就业公益巡回讲座第三站——南工大专场圆满落幕！</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-06-06</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            大学生实现高质量就业公益巡回讲座第三站——南工大专场圆满落幕！
          </div>
          <div class="titname">FOUND</div>
          <p>
            为帮助大学生做好职业生涯规划，树立正确的职业价值观，促进学生高质量就业。2023年4月-12月，南京秦淮财税金融服务协会联合南京财商人生教育科技有限公司及相关院校共同推出“大学生实现高质量就业公益巡回讲座”活动。继南财会计学院、南农大金融学院后，第三场公益讲座于5月25日下午在南京工业大学经济与管理学院顺利落下帷幕。
          </p>
					<div><img src="../../../assets/images/fq3Photo1.png" alt="" /></div>
          
          <p>
            本次讲座由南京秦淮财税金融服务协会会长、大华会计师事务所合伙人、南京财商人生教育科技有限公司创始人方维翔先生作主题演讲。方老师首先提出近几年来，新冠后疫情时代，国内经济形势受到不小冲击，各行各业都面临着挑战。而这些直接导致了就业率的骤降，近几年高校毕业生的就业形势不容乐观。接着方老师以他20多年的工作经验就同学们该如何适应这个新的时代，进行正确的职业选择和规划提出了建议和方向。他指出，随着人工智能时代的到来，会计师事务所的审计工作是不会被取代的，且未来可以获得高质量就业的机会，并通过实例告诉同学们，为什么去会计师事务所就业可以获得更广泛的职业发展机会和更高的职业回报，是一种高质量就业较好的选择。
          </p>
					<div> <img src="../../../assets/images/fq3Photo2.png" alt="" /></div>

         
          <p>
            方老师还向同学们详细解读了2022年12月26日中注协修订印发的《中国注册会计师行业人才胜任能力指南》中对会计师事务所助理人员的胜任能力要求，从而告诉同学们事务所是如何提升从业人员的能力。方老师引入“学历、能力、竞争力”三个力的概念，并且简单阐述了会计师事务所如何与三个力相结合，并且强调指出学历加能力加竞争力，这是我们实现高质量就业的一个保障。最后，方老师向同学们介绍了进入事务所的途径：校招和社招。针对大学生来说，校招最重要，尤其是要关注秋招。他提出，事务所主要在秋招招聘人员，每年11月开始预审到次年6月，在此期间需要录用大量人员，所以同学们要想进事务所，要抓住秋招机会。参加讲座的同学，除了会计专业，还有工程管理、信息管理与信息系统等专业的同学。方老师指出，与其他领域融合是会计师事务所的业务发展趋势，不管是内资八大还是四大，招聘时都早已不会拘泥于财经类专业，而是会吸纳各个不同专业的人才，所以也很欢迎非财经专业的同学加入事务所！
          </p>
					<div><img src="../../../assets/images/fq3Photo3.png" alt="" /></div>

          
          <p>
            讲座同样设置了互动问答环节，让同学们自由发言、互相交流。有位大三的同学希望了解进入会计师事务所工作几年后如何转入金融机构工作，方老师给他作了详细解答并给出一些建议。至此,讲座在热烈的氛围中圆满结束。
          </p>
					<div><img src="../../../assets/images/fq3Photo4.png" alt="" /></div>
          <p>
            本次讲座得到了南工大经管学院的领导、老师们的大力支持，在此向他们表示衷心的感谢，同时也祝福南工大的同学们可以收获满满，未来可以如愿开启高质量就业之路！
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>