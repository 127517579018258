<template>
  <div class="indexPage">
    <div class="index-banner">
      <img src="../assets/images/index/indexBanner.png" />
    </div>
    <div class="index-main">
      <div class="trends">
        <div class="trends-title">
          <div class="trends-title-left">
            <span></span>
            <span></span>
          </div>
          <div class="trends-title-right">
            <p>dynamic</p>
            <p>协会动态</p>
          </div>
        </div>
        <div class="trends-content">
          <div
            class="content-left animated"
            ref="testref"
            :class="{ fadeInLeft: isShow }"
          >
            <el-carousel height="425px">
              <el-carousel-item
                v-for="(item, index) in bannerList"
                :key="index"
              >
                <el-image
                  @click="goDetail(item.path)"
                  style="width: 100%; height: 100%"
                  :src="item.img"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            class="content-right animated"
            ref="testref"
            :class="{ fadeInRight: isShow }"
          >
            <div class="content-right-top">
              <div>协会新闻</div>
              <div>政策动态</div>
              <div>通知公告</div>
              <div @click="goNew">MORE+</div>
            </div>
            <div class="content-right-bottom">
              <div class="content-item" @click="toFiveteen">
                <p>
                 【喜讯】南京秦淮财税金融服务协会荣获“AAA等级社会组织”荣誉称号
                </p>
                <p>2024-01-25</p>
              </div>
              <div class="content-item" @click="toFourteen">
                <p>
                 履职尽责献良策 参政议政助发展
                </p>
                <p>2024-01-22</p>
              </div>
              <div class="content-item" @click="toThirteen">
                <p>
                 CPA执业圈财总分享会“业财融合（医药行业）”主题活动成功举行
                </p>
                <p>2023-11-01</p>
              </div>
              <div class="content-item" @click="toTwelve">
                <p>
                  南京秦淮财税金融服务协会方维翔会长、武孝奎秘书长应邀赴江苏省温州商会参观交流
                </p>
                <p>2023-10-25</p>
              </div>
              <div class="content-item" @click="toEleven">
                <p>
                  秦淮财税金融服务协会、财商人生就业指导进高校，为学子提供就业助力
                </p>
                <p>2023-10-24</p>
              </div>
              <div class="content-item" @click="toTen">
                <p>
                  南京秦淮财税金融服务协会、南京财商人生教育科技有限公司携手为南京财经大学毕业生提供就业指导服务
                </p>
                <p>2023-10-17</p>
              </div>
              <div class="content-item" @click="toNine">
                <p>
                  聚力赋能，助推梦想启航——记秦淮财税协会、财商人生携手为南审大毕业生提供就业指导服务
                </p>
                <p>2023-09-22</p>
              </div>
              <!-- <div class="content-item" @click="toInt">
                <p>关于会费标准的公告</p>
                <p>2023-07-03</p>
              </div> -->
              <!-- <div class="content-item" @click="toSeven">
                <p>
                  关于同意成立“南京秦淮财税金融服务协会人才开发研究院”的决议
                </p>
                <p>2023-06-13</p>
              </div> -->
              <!-- <div class="content-item" @click="toSex">
                <p>
                  大学生实现高质量就业公益巡回讲座第三站——南工大专场圆满落幕！
                </p>
                <p>2023-06-07</p>
              </div> -->
              <!-- <div class="content-item" @click="toFive">
                <p>
                  财经金融专业大学生实现高质量就业公益巡回讲座（南农大专场）成功举办
                </p>
                <p>2023-06-06</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="aboutUs">
        <div
          class="aboutUs-left animated hidden"
          ref="testref2"
          :class="{ fadeInLeft: isShowTwo }"
        >
          <div class="aboutUs-title">
            <div class="aboutUs-title-left">
              <p>ABOUT US</p>
              <p>关于我们</p>
            </div>
            <div class="aboutUs-title-right">
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="aboutUs-text">
            南京秦淮财税金融服务协会是由会计师、税务师、评估师、律师、造价师以及金融证券师等现代服务业专业人员和与之相对应的各类中介机构事务所、相关金融机构以及各类实体企业单位自愿组成的专业性、互助性服务型非营利性社会组织。<br />
            本协会的会员：单位会员、个人会员。
          </div>
          <div class="aboutUs-btn" @click="goAbout">
            <span>MORE+</span>
          </div>
        </div>
        <div
          class="aboutUs-right animated hidden"
          ref="testref2"
          :class="{ fadeInRight: isShowTwo }"
        >
          <img src="../assets/images/index/indexAbout.png" alt="" />
        </div>
      </div>
      <div
        class="work animated hidden"
        ref="testref3"
        :class="{ fadeInUp: isShowThree }"
      >
        <div class="work-title">
          <div class="work-title-left">
            <p>WORK</p>
            <p>当前工作项目</p>
          </div>
          <div class="work-title-right">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="workMain animated hidden"
      ref="testref3"
      :class="{ fadeInUp: isShowThree }"
    >
      <div class="workcontent">
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/work1.png') + ')',
          }"
        >
          <img src="../assets/images/index/icon1.png" alt="" />
          <img
            src="../assets/images/index/right.png"
            alt=""
            style="position: absolute; top: 45px; right: 45px"
          />
          <p>政府委托项目调研及培训</p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/Number1.png') + ')',
          }"
        >
          <p style="padding-top: 50px">
            承接政府部门的委托组织相关专业人员对财务、税务、法律和金融等相关专业方面的政策、问题进行研究、交流及研讨，并提供综合解决方案。接受委托对各类机构（企业）的人员及所属工作人员进行财务、税务、法律和金融等相关专业知识的培训。
          </p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/work2.png') + ')',
          }"
        >
          <img src="../assets/images/index/icon2.png" alt="" />
          <img
            src="../assets/images/index/down.png"
            alt=""
            style="position: absolute; bottom: 30px; left: 181px"
          />
          <p>财经系列专业培训</p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/Number3.png') + ')',
          }"
        >
          <p style="padding-top: 54px">
            吸纳高校、事务所、财经类培训机构加入平台，共建共治共享人才培养体系。结合实操培训项目，为事务所输送能即刻上岗的高质量的人才，节约岗前培训的时间与成本。对于事务所临时用人、紧急用人、批量用人的特殊情况给予绿色通道支持。
          </p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/work3.png') + ')',
          }"
        >
          <img src="../assets/images/index/icon3.png" alt="" />
          <img
            src="../assets/images/index/left.png"
            alt=""
            style="position: absolute; left: 45px; top: 45px"
          />
          <p>建设财经人才培养平台</p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/Number2.png') + ')',
          }"
          style="background: url(../assets/images/index/Number2.png) no-repeat"
        >
          <p style="padding-top: 56px">
            组织会员单位员工培训，提高现有初级人员岗位技能。通过与高校的合作关系，将培训下沉至学校，提前一个周期进行培养，使受训学员毕业后能无缝衔接事务所工作。组织CPA序列相关培训，全面提升从业人员资质和水平。
          </p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/work0.png') + ')',
          }"
        >
          <img src="../assets/images/index/quan.png" alt="" />
          <img
            src="../assets/images/index/right.png"
            alt=""
            style="position: absolute; right: 45px; top: 45px"
          />
          <p>组建财务专家委员会</p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/Number4.png') + ')',
          }"
        >
          <p style="padding-top: 68px">
            邀请各会计事务所专家组建财务专业委员会，研究审计理论与实践，为协会会员提供技术咨询，参与制订、修订行业标准，对标准的先进性、经济性、可行性进行前期的论证。组织行业技术成果的鉴定和推广应用。
          </p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/work4.png') + ')',
          }"
        >
          <img src="../assets/images/index/icon4.png" alt="" />
          <img
            src="../assets/images/index/down.png"
            alt=""
            style="position: absolute; bottom: 30px; left: 181px"
          />
          <p>建设多元化平台</p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/Number6.png') + ')',
          }"
        >
          <p style="padding-top: 50px">
            未来企业间竞争最主要的是人才的竞争，我们利用参与国内外经济交流与合作机制，积极搭建各类企业人才合作信息平台，特别是为区内企业招聘人才引入智囊提供信息和窗口。同时更为各类人才提供充分发挥能力舞台。
          </p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/work5.png') + ')',
          }"
        >
          <img src="../assets/images/index/icon5.png" alt="" />
          <img
            src="../assets/images/index/left.png"
            alt=""
            style="position: absolute; left: 45px; top: 45px"
          />
          <p>协助企业对外招才引智</p>
        </div>
        <div
          :style="{
            background:
              'url(' + require('../assets/images/index/Number5.png') + ')',
          }"
        >
          <p style="padding-top: 64px">
            整合政府、企业、高校各方资源，将协会服务辐射至财务、税务、金融、法律、造价等相关领域，共同建设多元化平台，实现行业互助和资源共享，形成财税金融生态圈，促进产业融合，实现共同发展。
          </p>
        </div>
      </div>
    </div>
    <div class="partner">
      <div
        class="partner-title animated hidden"
        ref="testref4"
        :class="{ fadeInUp: isShowFour }"
      >
        <div class="partner-title-left">
          <span></span>
          <span></span>
        </div>
        <div class="partner-title-right">
          <p>PARTNERS</p>
          <p>协会成员</p>
        </div>
      </div>
      <div
        class="partner-main animated hidden"
        ref="testref4"
        :class="{ fadeInUp: isShowFour }"
      >
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <img src="../assets/images/index/indexPartner1.png" />
        </el-card>
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <img src="../assets/images/index/indexPartner2.png" />
        </el-card>
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <img @click="goFq" src="../assets/images/fqlogo.png" />
        </el-card>
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <img
            src="../assets/images/index/indexPartner4.png"
          />
        </el-card>
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <img
            @click="goDaHuaCpa"
            src="../assets/images/index/indexPartner5.png"
          />
        </el-card>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
// import myFooter from '@/components/myFooter.vue';
export default {
  // components: { myFooter },
  data() {
    return {
      bannerList: [
        { img: require("../assets/images/图片 1.1.jpg"), path: "/newSex" },
        { img: require("../assets/images/图片 1.2.jpg"), path: "/newFive" },
        { img: require("../assets/images/图片 1.3.jpg"), path: "/newFour" },
        { img: require("../assets/images/图片 1.4.jpg"), path: "/newTwo" },
      ],
      isShow: false,
      isShowTwo: false,
      isShowThree: false,
      isShowFour: false,
      currentScroll: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isShow = true;
  },
  methods: {
    handleScroll() {
      this.currentScroll = window.pageYOffset;
      // console.log(this.currentScroll);
      // // console.log(this.$refs.testref3);
      // console.log(this.$refs.testref4.offsetTop);
      if (this.$refs.testref2) {
        if (this.currentScroll >= this.$refs.testref2.offsetTop - 900) {
          this.isShowTwo = true;
        }
      }
      if (this.$refs.testref3) {
        if (this.currentScroll >= this.$refs.testref3.offsetTop - 800) {
          this.isShowThree = true;
        }
      }
      if (this.$refs.testref4) {
        if (this.currentScroll >= this.$refs.testref4.offsetTop - 1000) {
          this.isShowFour = true;
        }
      }
    },
    toFiveteen(){
      this.$router.push("/newFiveteen");
    },
    toFourteen() {
      this.$router.push("/newFourteen");
    },
    toThirteen(){
      this.$router.push("/newThirteen");

    },
    toTwelve() {
      this.$router.push("/newTwelve");
    },
    toEleven() {
      this.$router.push("/newEleven");
    },
    toTen() {
      this.$router.push("/newTen");
    },
    toNine() {
      this.$router.push("/newNine");
    },
    toInt() {
      this.$router.push("/newEnghit");
    },
    toThree() {
      this.$router.push("/newThree");
    },
    toFour() {
      this.$router.push("/newFour");
    },
    toFive() {
      this.$router.push("/newFive");
    },
    toSex() {
      this.$router.push("/newSex");
    },
    toSeven() {
      this.$router.push("/newSeven");
    },
    goAbout() {
      this.$router.push("/aboutUs/brief");
    },
    goNew() {
      this.$router.push("/trends/news");
    },
    goFq() {
      window.open("https://fq-up.com/home", "_blank");
    },
    // goDaHua() {
    //   window.open("http://www.dahuasgw.com/", "_blank");
    // },
    goDaHuaCpa() {
      window.open("https://www.dahua-cpa.com/", "_blank");
    },
    goDetail(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.indexPage {
  .hidden {
    opacity: 0;
  }
  .index-banner {
    img {
      width: 100%;
    }
  }
  .index-main {
    width: 1200px;
    margin: auto;

    .trends {
      .trends-title {
        margin-top: 80px;
        display: flex;
        margin-bottom: 60px;
        .trends-title-left {
          span:first-child {
            width: 11px;
            height: 81px;
            display: inline-block;
            border: 2px solid #333333;
            margin-right: 5px;
          }
          span:last-child {
            width: 5px;
            height: 30px;
            display: inline-block;
            vertical-align: top;
            border: 2px solid #b38d54;
            margin-right: 80px;
          }
        }
        .trends-title-right {
          line-height: 24px;
          p:first-child {
            text-transform: uppercase;
            width: 193px;
            height: 46px;
            font-size: 54px;
            font-family: Impact;
            font-weight: 400;
            color: #333;
            vertical-align: top;
            margin-top: 10px;
            display: inline-block;
          }
          p:last-child {
            font-size: 20px;
            /* font-family: Microsoft YaHei; */
            font-weight: 400;
            color: #333;
            margin-top: 7px;
          }
        }
      }
      .trends-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        .content-left {
          /deep/.el-carousel {
            .el-image {
              cursor: pointer;
            }
            .el-carousel__indicators--horizontal {
              bottom: 25px;
              left: 90%;
            }
            .el-carousel__container {
              width: 577px;
            }
            .el-carousel__button {
              border-radius: 50%;
              width: 6px;
              height: 6px;
            }
          }
        }

        .content-right {
          width: 604px;
          .content-right-top {
            display: flex;
            div {
              width: 137px;
              height: 45px;
              line-height: 45px;
              border: 1px solid #b38d54;
              color: #b38d54;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
            }
            div:nth-child(2) {
              border-left: none;
              border-right: none;
            }
            div:first-child {
              background: #b38d54;
              color: white;
              cursor: pointer;
            }
            div:last-child {
              margin-left: 60px;
              cursor: pointer;
            }
          }
          .content-right-bottom {
            margin-top: 20px;
            .content-item {
              display: flex;
              justify-content: space-between;
              padding: 15px 0;
              border-bottom: 1px dashed #999999;
              p {
                font-size: 14px;
                color: #212121;
              }
              p:first-child {
                white-space: nowrap; /* 不允许文本自动换行 */
                overflow: hidden; /* 隐藏溢出的内容 */
                text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
                width: 80%; /* 定义文本的宽度 */
              }
            }
            .content-item:hover {
              p {
                color: #b38d54;
              }
              cursor: pointer;
            }
          }
        }
      }
    }
    .aboutUs {
      margin-top: 117px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      .aboutUs-left {
        width: 530px;
        .aboutUs-title {
          display: flex;
          justify-content: space-between;
          .aboutUs-title-left {
            p:first-child {
              text-transform: uppercase;
              width: 210px;
              height: 70px;
              font-size: 54px;
              font-family: Impact;
              font-weight: 400;
              color: #333;
              vertical-align: top;
              margin-top: -10px;
            }
            p:last-child {
              font-size: 20px;
              font-weight: 400;
              color: #333;
            }
          }
          .aboutUs-title-right {
            span:first-child {
              width: 5px;
              height: 30px;
              display: inline-block;
              vertical-align: top;
              border: 2px solid #b38d54;
            }
            span:last-child {
              width: 11px;
              height: 81px;
              display: inline-block;
              border: 2px solid #333333;
              margin-left: 5px;
            }
          }
        }
        .aboutUs-text {
          margin-top: 83px;
          color: #999999;
          line-height: 30px;
        }
        .aboutUs-btn {
          margin-top: 55px;
          span {
            text-transform: uppercase;
            color: #b38d54;
            width: 137px;
            height: 45px;
            line-height: 45px;
            border: 1px solid #b38d54;
            color: #b38d54;
            text-align: center;
            display: inline-block;
            cursor: pointer;
          }
        }
      }
      .aboutUs-right {
        img {
          width: 603px;
          height: 425px;
        }
      }
    }
    .work {
      margin-top: 125px;
      .work-title {
        margin-bottom: 57px;
        display: flex;
        justify-content: flex-end;
        .work-title-left {
          p:first-child {
            text-transform: uppercase;
            font-size: 54px;
            font-family: Impact;
            font-weight: 400;
            color: #333;
            vertical-align: top;
            display: inline-block;
          }
          p:last-child {
            margin-top: 7px;
            font-size: 20px;
            font-weight: 400;
            color: #333;
            text-align: center;
          }
        }
        .work-title-right {
          margin-top: 10px;
          margin-left: 43%;
          span:first-child {
            width: 5px;
            height: 30px;
            display: inline-block;
            vertical-align: top;
            border: 2px solid #b38d54;
          }
          span:last-child {
            margin-left: 5px;
            width: 11px;
            height: 81px;
            display: inline-block;
            border: 2px solid #333333;
          }
        }
      }
    }
  }
  .workMain {
    width: 100%;
    margin: 40px 0 130px 0;
    background-color: #f5f5f5;
    .workcontent {
      width: 1200px;
      height: 1120px;
      font-size: 0;
      margin: 0 auto;
      div {
        width: 400px;
        height: 280px;
        text-align: center;
        float: left;
        line-height: 30px;
        position: relative;
      }
      img {
        margin-top: 80px;
      }
      div:nth-of-type(odd) p {
        font-size: 20px;
        color: #fff;
        text-align: center;
        margin-top: 20px;
        font-weight: 600;
      }
      div:nth-child(7) p {
        font-size: 20px;
        color: #fff;
        text-align: center;
        margin-top: 20px;
        font-weight: 600;
      }
      div:nth-of-type(even) p {
        font-size: 16px;
        color: #333;
        padding-left: 45px;
        padding-right: 45px;
        line-height: 30px;
        text-align: justify;
      }
    }
  }
  .partner {
    width: 1200px;
    margin: auto;
    .partner-title {
      display: flex;
      justify-content: flex-start;
      .partner-title-left {
        span:first-child {
          width: 11px;
          height: 81px;
          display: inline-block;
          border: 2px solid #333333;
          margin-right: 5px;
        }
        span:last-child {
          width: 5px;
          height: 30px;
          display: inline-block;
          vertical-align: top;
          border: 2px solid #b38d54;
        }
      }
      .partner-title-right {
        margin-left: 38%;
        p:first-child {
          text-transform: uppercase;
          width: 210px;
          height: 70px;
          font-size: 54px;
          font-family: Impact;
          font-weight: 400;
          color: #333;
          vertical-align: top;
          margin-top: -10px;
        }
        p:last-child {
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }
    }
    .partner-main {
      margin-top: 65px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 130px;
      .el-card {
        height: 135px;
        img {
          cursor: pointer;
        }
      }
      .el-card:nth-child(n + 5) {
        margin-top: 33px;
      }
    }
  }
}
</style>