<template>
  <div class="aboutUsPage">
    <div class="aboutUs-banner">
      <img src="../assets/images/dynamics.png" alt="" />
    </div>
    <div class="aboutUs-main">
      <div class="main-content">
        <el-container>
          <el-aside width="280px">
            <div>
              <p class="aboutTitle">DYNAMICS</p>
              <el-menu
                ref="menu"
                :default-active="$route.path"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                @select="handleSelect"
                background-color="#fff"
                text-color="#999999"
                active-text-color="#B38D53"
                router
                ><el-menu-item index="/trends/news">
                  <i class="el-icon-caret-right"></i>
                  <span slot="title">协会新闻</span>
                </el-menu-item>
                <el-submenu index="/trends/interpret">
                  <template slot="title">
                    <i
                      :style="{ color: isRed ? '#b18b53' : '#f5f5f5' }"
                      :class="
                        isActive
                          ? 'rotote el-icon-caret-right'
                          : 'el-icon-caret-right'
                      "
                    ></i>
                    <span>政策动态</span>
                  </template>

                  <el-menu-item index="/trends/interpret"
                    >最新政策解读</el-menu-item
                  >
                  <el-menu-item index="/trends/industryDynamics"
                    >业界动态</el-menu-item
                  >
                </el-submenu>
                <el-menu-item index="/trends/notice">
                  <i class="el-icon-caret-right"></i>
                  <span slot="title">通知公告</span>
                </el-menu-item>
              </el-menu>
            </div>
          </el-aside>
          <el-main>
            <router-view></router-view>
          </el-main>
        </el-container>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isRed: false,
    };
  },
  mounted() {
    if (this.$route.path == "/trends/interpret") {
      this.isActive = true;
    }
  },
  methods: {
    handleOpen() {
      this.isActive = true;
    },
    handleSelect(index, indexPath) {
      // console.log(indexPath);
      if (index !== "/trends/interpret") {
        this.$refs.menu.close("/trends/interpret");
      }
      if (index == "/trends/interpret" || index == "/trends/industryDynamics") {
        this.isRed = true;
      }else{
        this.isRed = false;

      }
      if (indexPath[0] == "/trends/interpret") {
        this.isActive = true;
      } else if (indexPath[0] == "/trends/interpret") {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
    handleClose() {
      this.isActive = false;
    },
  },
  computed: {
    defaultActive() {
      let pathName = this.$route.name;
      // console.log(this.$route.name);
      if (pathName == pathName) {
        let path = `/aboutUs/${pathName}`;
        return path;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.aboutUsPage {
  width: 100%;
  .aboutUs-banner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .aboutUs-main {
    width: 100%;
    background: #f5f5f5;
    padding: 30px 0;
    .main-content {
      width: 1208px;
      box-sizing: border-box;
      margin: auto;
      background: #ffffff;
      padding: 0 70px;
      /deep/.el-container {
        padding-top: 55px;
        padding-bottom: 55px;
        .el-aside {
          overflow: initial;
          margin-right: 70px;
          .aboutTitle {
            font-size: 20px;
            font-family: Impact;
            font-weight: 400;
            color: #333;
            text-align: center;
            padding-bottom: 15px;
            border-bottom: 2px solid #f5f5f5;
          }
          .el-menu {
            border: none;
            .el-menu-item [class^="el-icon-"] {
              color: #f5f5f5;
              margin-right: 27px;
              margin-left: 45px;
              font-size: 20px;
              width: 20px;
            }
            .el-submenu [class^="el-icon-"] {
              color: #f5f5f5;
              margin-right: 27px;
              margin-left: 45px;
              font-size: 20px;
              width: 20px;
            }
            .el-submenu {
              border-bottom: 2px solid #f5f5f5;

              .el-menu-item {
                font-size: 15px;
                border: none;
                height: 45px;
                line-height: 18px;
                padding-left: 120px !important;
              }
              .is-active {
                border: none !important;
              }
              .el-submenu__title {
                font-size: 16px;
                height: 75px;
                line-height: 73px;
                background: #fff !important;
              }
              .el-submenu__title i {
                transition: transform 0.3s;
              }
              .el-submenu__title:hover {
                span {
                  color: #b18b53;
                }
              }
              .rotote {
                color: #f5f5f5;
                margin-right: 27px;
                margin-left: 45px;
                font-size: 20px;
                transform: rotateZ(90deg);
              }
              .el-submenu__icon-arrow {
                display: none;
              }
            }
            .el-submenu.is-active {
              border-bottom: 2px solid #b18b53;
            }

            .el-menu-item {
              font-size: 16px;
              height: 75px;
              line-height: 73px;
              border-bottom: 2px solid #f5f5f5;
              background: #ffffff !important;
            }
            .el-menu-item.is-active {
              border-bottom: 2px solid #b18b53;
              .el-icon-caret-right {
                color: #b18b53 !important;
              }
            }
            .el-menu-item:hover {
              color: #b38d53 !important;
            }
          }
        }
      }
    }
  }
}
</style>