<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/aboutUs/organization' }"
          >组织架构</el-breadcrumb-item
        >
        <el-breadcrumb-item>协会组织架构框架</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">协会组织架构框架</div>
      <div class="titname">FRAMEWORK</div>
      <img src="../../../assets/images/jiagou.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toInt() {
      this.$router.push("/aboutUs/introduction");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
}
</style>