<template>
  <div class="homePage">
    <el-container>
      <el-header height="90px"
        ><div class="header-content">
          <div class="logo">
            <img  @click="gohome" src="../assets/images/index/indexLogoLight.png" alt="" />
          </div>
          <el-menu
            :default-active="defaultActive"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            text-color="#212121"
            active-text-color="#B38D54"
            router
          >
            <el-menu-item index="/index">首页</el-menu-item>
            <el-submenu index="/aboutUs" popper-class="submenuOne">
              <template slot="title"
                ><span @click="goAbout">关于协会</span></template
              >
              <el-menu-item index="/aboutUs/brief">协会简介</el-menu-item>
              <div class="scroll"></div>
              <el-menu-item index="/aboutUs/organization"
                >组织架构</el-menu-item
              >
              <div class="scroll"></div>
              <el-menu-item index="/aboutUs/rules">协会章程</el-menu-item>
              <div class="scroll"></div>
              <el-menu-item index="/aboutUs/leader">协会领导</el-menu-item>
              <div class="scroll"></div>
              <el-menu-item index="/aboutUs/apply">入会申请</el-menu-item>
            </el-submenu>
            <el-submenu index="/committee/cpa" popper-class="submenuTwo">
              <template slot="title"
                ><span @click="goCom">专业委员会</span></template
              >
              <el-menu-item index="/committee/cpa">人才开发研究院</el-menu-item>
              <div class="scroll"></div>
              <el-menu-item index="/committee/finance"
                >财务专业委员会</el-menu-item
              >
              <div class="scroll"></div>
              <el-menu-item index="/committee/administration"
                >税务专业委员会</el-menu-item
              >
              <div class="scroll"></div>

              <el-menu-item index="/committee/law">法律专业委员会</el-menu-item>
              <div class="scroll"></div>

              <el-menu-item index="/committee/metals"
                >金融专业委员会</el-menu-item
              >
              <div class="scroll"></div>

              <el-menu-item index="/committee/consultant"
                >行业顾问委员会</el-menu-item
              >
              <div class="scroll"></div>

              <el-menu-item index="/committee/skill"
                >职业技能培训委员会</el-menu-item
              >
            </el-submenu>
            <el-submenu index="/trends/news" popper-class="submenuThree">
              <template slot="title"
                ><span @click="goTrends">协会动态</span></template
              >

              <el-menu-item index="/trends/news">协会新闻</el-menu-item>
              <div class="scroll"></div>

              <el-menu-item index="/trends/interpret">政策动态</el-menu-item>
              <div class="scroll"></div>

              <el-menu-item index="/trends/notice">通知公告</el-menu-item>
            </el-submenu>
            <el-menu-item index="/contactUs">联系我们</el-menu-item>
          </el-menu>
        </div></el-header
      >
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/index",
    };
  },
  mounted() {
    this.activeIndex = this.$route.path;
  },
  watch: {
    $route() {
      this.setCurrentRoute();
    },
  },
  computed: {
    defaultActive() {
      let pathName = this.$route.name;
      // console.log(this.$route.name);
      // if (pathName == pathName) {
      //   let path =`/aboutUs/${pathName}`;
      //   return path;
      // } 
      if (pathName == "brief") {
        let path = "/aboutUs/brief";
        return path;
      }
      if (pathName == "organization") {
        let path = "/aboutUs/organization";
        return path;
      }
      if (pathName == "rules") {
        let path = "/aboutUs/rules";
        return path;
      }
      if (pathName == "leader") {
        let path = "/aboutUs/leader";
        return path;
      }
      if (pathName == "apply") {
        let path = "/aboutUs/apply";
        return path;
      }
      if (pathName == "cpa") {
        let path = "/committee/cpa";
        return path;
      }
      if (pathName == "finance") {
        let path = "/committee/finance";
        return path;
      }
      if (pathName == "administration") {
        let path = "/committee/administration";
        return path;
      }
      if (pathName == "law") {
        let path = "/committee/law";
        return path;
      }
      if (pathName == "metals") {
        let path = "/committee/metals";
        return path;
      }
      if (pathName == "consultant") {
        let path = "/committee/consultant";
        return path;
      }
      if (pathName == "skill") {
        let path = "/committee/skill";
        return path;
      }
      if (pathName == "news") {
        let path = "/trends/news";
        return path;
      }
      if (pathName == "interpret") {
        let path = "/trends/interpret";
        return path;
      }
      if (pathName == "notice") {
        let path = "/trends/notice";
        return path;
      }
      if (pathName == "contactUs") {
        let path = "/contactUs";
        return path;
      }
      if (pathName == "index") {
        let path = "/index";
        return path;
      }
    },
  },
  methods: {
    gohome(){
      this.$router.push('/index')
    },
    setCurrentRoute() {
      this.activeIndex = this.$route.path;
    },
    handleSelect() {},
    goAbout() {
      this.$router.push("/aboutUs/brief");
    },
    goCom() {
      this.$router.push("/committee/cpa");
    },
    goTrends() {
      this.$router.push("/trends/news");
    },
  },
};
</script>

<style lang="less" scoped>
.homePage {
  width: 100%;
  /deep/.el-header {
    padding: 0;
    .header-content {
      width: 1200px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo{
        cursor: pointer;
      }
      .el-menu {
        border: none;
        height: 100%;
        .el-menu-item {
          height: 90px;
          line-height: 90px;
          margin-left: 44px;
          font-size: 16px;
        }
        .el-menu-item:hover {
          color: #b38d54 !important;
          border-bottom: 6px solid #b38d54 !important;
        }
        .el-submenu:hover {
          .el-submenu__title {
            color: #b38d54 !important;
            border-bottom: 6px solid #b38d54 !important;
          }
        }
        .el-menu-item.is-active {
          border-bottom: 6px solid #b38d54;
        }
        .el-submenu.is-active .el-submenu__title {
          border-bottom: 6px solid #b38d54;
        }
        .el-submenu__title {
          font-size: 16px;
          height: 90px;
          line-height: 90px;
          margin-left: 44px;
          span {
            margin-top: -4px;
            display: inline-block;
          }
        }
        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }
  }
  /deep/ .el-main {
    padding: 0;
  }
}
</style>