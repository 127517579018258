<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item>协会章程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">南京秦淮财税金融服务协会章程</div>
      <div class="titname">CHARTER</div>
      <h4>第一章 总则</h4>
      <p>
        第一条
        本协会名称为南京秦淮财税金融服务协会（以下简称“协会”）。英文名称为Financial
        Services Association Nanjing，缩写为Nanjing FSA。
      </p>
      <p>
        第二条
        本协会是由会计师、税务师、评估师、律师、造价师以及金融证券师等现代服务业专业人员和与之相对应的各类中介机构事务所、相关金融机构以及各类实体企业单位自愿组成的
        专业性、互助性服务型非营利性社会组织。本协会的会员：单位会员、个人会员。
      </p>
      <p>
        第三条
        本协会的宗旨：融合现代服务业的各专业机构及人员，为金融服务于实体经济提供专业服务，促进金融的稳健运行，防范和化解金融风险；为企业建立现代公司管理制度提供系列方案，促进企业规范、健康、可持续发展，提高公司治理水平，完善市场经济体系。
      </p>
      <p>
        本协会根据中国共产党章程的规定，设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚。
      </p>
      <p>
        第四条
        本协会的登记管理机关是南京市秦淮区民政局，业务主管单位是南京市秦淮区财政局。本协会接受登记管理机关和相关部门的业务指导和监督管理。
      </p>
      <p>
        第五条
        本协会可以根据工作需要设立分支机构、代表机构。本协会的分支机构、代表机构是本协会的组成部分，不具有法人资格，不得另行制订章程，在授权的范围内发展会员、开展活动，法律责任由本协会承担。
      </p>
      <p>第六条 本协会住所在江苏省南京市。</p>

      <h4>第二章 服务范围</h4>
      <p>第七条 本协会的服务范围：</p>
      <p>
        (一)
        开展财务、税务、法律和金融等相关专业方面研究、交流、投融资平台建设。秉承诚实互信原则，基于协会成员专业性，架设辖区内的开发区及孵化器平台企业与金融机构沟通桥梁。
      </p>
      <p>
        (二)
        政府委托项目、专业咨询培训与会展。承接政府部门的委托，组织相关专业人员对财务、税务、法律和金融等相关专业方面的政策、问题进行研究、交流，并提供综合解决方案。并对各类机构（企业）的人员进行财务、税务、法律和金融等相关专业知识的培训；组织专业机构和个人对政府部门及各类企业的问题提供咨询服务。
      </p>
      <p>
        (三)
        促进科技服务创新成果转化。参与国内外经济交流与合作，建立“产学研”一体的应用体系，以提升区域开发区及孵化器平台企业转型升级为目标，积极提供科技创新成果信息，并协助其科技创新成果转化工作。
      </p>
      <p>
        (四)
        参与国内外交流与合作。利用国内外交流与合作机制，积极搭建各类企业合作信息平台，协助政府部门和区内的开发区及孵化器平台招商引资，特别是符合我区产业发展政策效益较好的企业，以增强区内财税收入。
      </p>
      <p>(五) 其他应由本协会承担的服务职责。</p>

      <h4>第三章 会员</h4>
      <p>第八条 本协会的会员分为个人会员和单位会员。</p>
      <p>第九条 自愿申请加入本协会的会员，必须具备下列条件：</p>
      <p>(一) 拥护本协会的章程；</p>
      <p>(二) 从事的工作须与本协会的服务范围相关；</p>
      <p>(三) 遵守宪法和法律；</p>
      <p>(四) 遵守执业纪律和职业道德规范。</p>
      <p>(五) 本协会认为需要的其他条件。</p>
      <p>第十条 会员入会的程序是：</p>
      <p>(一) 提交入会申请书；</p>
      <p>(二) 由两名以上会员或一名以上理事推荐；</p>
      <p>(三) 经理事会或常务理事会讨论通过</p>
      <p>(四) 颁发会员证并公告。</p>
      <p>第十一条 会员享有下列权利：</p>
      <p>(一) 本协会的选举权、被选举权和表决权；</p>
      <p>(二) 参加本协会的活动；</p>
      <p>(三) 优先获得本协会服务；</p>
      <p>(四) 对本协会工作进行的知情权、建议权和监督权；</p>
      <p>(五) 入会自愿、退会自由；</p>
      <p>(六) 会员可享有的其他权利。</p>
      <p>第十二条 会员履行下列义务：</p>
      <p>(一) 遵守本协会章程；</p>
      <p>(二) 执行本协会的决议；</p>
      <p>(三) 维护本协会的合法权益；</p>
      <p>(四) 按时参加本协会的的活动，完成本协会交办的工作；</p>
      <p>(五) 向本协会反映情况，提供有关资料；</p>
      <p>(六) 按规定缴纳会费；</p>
      <p>(七) 会员应履行的其他义务。</p>
      <p>
        会员退会，应书面通知本协会，且交回会员证并公告；拒不交回的，由本会宣告作废。
      </p>
      <p>
        未经许可，会员一年不缴纳会费或连续一年不参加本会活动的，视为自动退会。经理事会确认，本会取消其会员资格。
      </p>
      <p>
        会员如有违反本章程的行为，由理事会提出批评教育；批评教育无效或者情节严重的，经理事会或者常务理事会表决通过，可以暂停其会员资格或者提交会员大会表决通过，取消其会员资格，予以除名并公告。
      </p>
      <p>本会建立完整的会员名册，并根据变化情况及时调整。</p>
      <p>第十三条 会员退会应书面通知本协会，并交回会员证并公告。</p>
      <p>
        第十四条
        会员如有违反本章程的行为，经理事会或者常务理事会表决通过，可以暂停其会员资格或者予以除名并公告。
      </p>
      <p>
        第十五条
        会员退会、被暂停会员资格或者被除名后，其在本协会相应的职务、权利、义务自行终止。
      </p>
      <h4>第四章 组织机构</h4>
      <h4>第一节 会员大会</h4>
      <p>第十六条 本协会的最高权利机构是会员大会，其职权是：</p>
      <p>(一) 制定和修改章程；</p>
      <p>(二) 制定和修改会费标准；</p>
      <p>(三) 制定和修改理事、常务理事、负责人产生办法；</p>
      <p>(四) 选举或者罢免理事、监事；</p>
      <p>(五) 审议理事会的工作报告和财务报告；</p>
      <p>(六) 审议监事的工作报告；</p>
      <p>(七) 决定名称变更、终止的重大事宜；</p>
      <p>(八) 审议其他重大事项；</p>
      <p>第十七条 会员大会每1年召开1次。</p>
      <p>
        会员大会每届5年，每1年召开一次。因特殊情况需提前或者延期换届的，须由理事会表决通过，报业务主管单位审查并经登记管理机关批准同意。延期换届最长不超过1年。
      </p>
      <p>本协会召开会员大会，须提前20日将会议的议题书面通知会员。</p>
      <p>
        第十八条 经理事会或者本协会35%以上的会员提议，应当召开临时会员大会。
      </p>
      <p>
        第十九条
        会员大会须有2/3以上的会员出席方能召开，其决议具备下列条件方能生效：
      </p>
      <p>
        (一)
        制定和修改章程，决定更名和终止事宜，制定和修改会费标准，须经到会会员2/3以上表决通过；
      </p>
      <p>
        (二) 选举理事，应当由得票数多的候选人当选，且得票数不低于总票数的50%；
      </p>
      <p>其他决议，须经到会会员(会员代表)过半数表决通过。</p>
      <h4>第二节 理事会</h4>
      <p>
        第二十条
        理事会是会员大会的执行机构，在会员大会闭会期间领导本协会开展工作，对会员大会负责。
      </p>
      <p>理事人数最高不超过会员的30%。</p>
      <p>第二十一条 本协会理事应当符合以下条件：</p>
      <p>(一) 在会员中具有代表性；</p>
      <p>(二) 能正常行使会员权利、履行会员义务；</p>
      <p>(三) 在本协会的业务领域内具有一定的影响力；</p>
      <p>(四) 积极支持协会工作；</p>
      <p>(五) 会员大会要求的其他条件。</p>
      <p>
        第二十二条
        单位理事的代表由该单位的主要负责人担任。单位调整理事代表，由其书面通知本协会，报理事会或者常务理事会备案。该理事同时为常务理事的，一并调整。
      </p>
      <p>第二十三条 理事会的职权是：</p>
      <p>(一) 制定会员代表产生办法和分配名额；</p>
      <p>(二) 筹备召开会员大会；</p>
      <p>(三) 执行会员大会决议；</p>
      <p>(四) 决定内设机构、分支机构、代表机构的设立、变更和终止；</p>
      <p>(五) 决定副秘书长和内设机构主要负责人的人选；</p>
      <p>(六) 领导本协会各机构开展工作；</p>
      <p>(七) 选举和罢免常务理事、秘书长、副理事长、理事长；</p>
      <p>(八) 向会员大会提交工作报告和财务报告；</p>
      <p>(九) 制定内部管理制度；</p>
      <p>(十) 审议年度财务预算、决算；</p>
      <p>(十一) 决定其他重大事项。</p>
      <p>
        第二十四条
        理事会每届5年。因特殊情况需提前或者延期换届的，须由理事会表决通过，报登记管理机关批准。延期换届最长不超过1年。
      </p>
      <p>
        第二十五条
        理事会会议须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。理事因特殊情况不能到会的，可书面委托1名代表参加会议并行使表决权。
      </p>
      <p>
        第二十六条
        理事会选举常务理事、负责人，应当由得票数多的候选人当选，且得票数不低于总票数70%。
      </p>
      <p>
        第二十七条
        本理事会每年至少召开１次会议，情况特殊的，可采用通讯形式召开。
      </p>
      <p>第二十八条 经理事长或者35％的理事提议，应当召开临时理事会会议。</p>
      <h4>第三节 常务理事会</h4>
      <p>第二十九条 本协会不设常务理事会。</p>
      <h4>第四节 负责人</h4>
      <p>第三十条 本协会负责人须具备下列条件：</p>
      <p>(一) 坚持党的路线、方针、政策，具备良好的政治素质；</p>
      <p>(二) 在本协会业务领域和活动地域内有较大的影响；</p>
      <p>(三) 年龄不超过70周岁，秘书长为专职；</p>
      <p>(四) 具有完全民事行为能力；</p>
      <p>(五) 能够忠实、勤勉履行职责、维护本协会和会员的合法权益；</p>
      <p>(六) 无法律法规规章和政策规定不得担任的其他情形。</p>
      <p>第三十一条 本协会法定代表人不兼任其他社会协会的法定代表人。</p>
      <p>第三十二条 本协会会长行使下列职权：</p>
      <p>(一) 领导理事会工作；</p>
      <p>(二) 召集和主持理事会；</p>
      <p>(三) 检查会员大会、理事会、各项会议决议的落实情况；</p>
      <p>(四) 代表本协会签署重要文件；</p>
      <p>(五) 章程规定的其他职权。</p>
      <p>第三十三条 秘书长协助理事长开展工作，行使下列职权：</p>
      <p>(一) 主持内设机构开展日常工作；</p>
      <p>
        (二)
        提名副秘书长及内设机构和实体机构主要负责人，交理事会或者常务理事会决定；
      </p>
      <p>(三) 提名专职工作人员的聘用，由理事会或常务理事会决定；</p>
      <p>(四) 拟定年度工作报告和计划，报理事会或常务理事会审议；</p>
      <p>(五) 拟订内部管理规章制度，报理事会或常务理事会批准；</p>
      <p>(六) 拟订年度财务预算、决算报告，报理事会或常务理事会审议；</p>
      <p>(七) 协调各分支机构、代表机构、实体机构开展工作；</p>
      <p>(八)处理其他日常事务。</p>
      <p>
        第三十四条
        会员大会、理事会、常务理事会应当制作会议记录。形成决议的，应当制作书面决议，并由负责人审阅、签名。会议记录、会议决议应当以适当方式向会员通报。
      </p>
      <p>负责人的选举结果须报登记管理机关备案并向会员公开。</p>
      <h4>第五节 监事</h4>
      <p>第三十五条 本协会设监事1名。监事任期和理事任期一致，期满可以连任。</p>
      <p>本协会不设监事会。</p>
      <p>
        第三十六条 本协会的负责人、理事、常务理事和财务管理人员不得兼任理事。
      </p>
      <p>第三十七条 监事行使下列职权：</p>
      <p>
        (一)
        列席理事会、常务理事会会议，对理事会、常务理事会决议事项提出质询或建议；
      </p>
      <p>
        (二)
        对理事、常务理事执行本协会职务的行为进行监督，对违反法律法规和本协会章程或者会员大会决议的负责人、常务理事、理事提出依程序罢免的建议；
      </p>
      <p>(三) 检查本协会的财务报告，向会员大会报告监事工作和提出建议；</p>
      <p>
        (四)
        对负责人、常务理事、理事、财务管理人员损害本协会利益的行为，及时予以纠正;
      </p>
      <p>
        (五) 向登记管理机关以及税务、会计主管部门反映本协会工作中存在的问题；
      </p>
      <p>(六) 决定其他应由监事审议的事项。</p>
      <h4>第五章 财产的管理和使用</h4>
      <p>第三十八条 本协会的收入来源于：</p>
      <p>(一) 会费；</p>
      <p>(二) 捐赠；</p>
      <p>(三) 政府资助；</p>
      <p>(四) 在核准的业务范围内开展活动或服务的收入；</p>
      <p>(五) 利息；</p>
      <p>(六) 其他合法收入。</p>
      <p>
        第三十九条
        本协会的收入及其使用情况应当向会员大会公布，接受会员大会的监督检查。
      </p>
      <p>
        本协会接受境外捐赠收入的，须将接受捐赠和使用的情况向登记管理机关报告。
      </p>
      <p>第四十条 本协会经费主要用于：</p>
      <p>(一) 本章程规定的业务范围；</p>
      <p>(二) 必要的行政办公和人员薪酬支出；</p>
      <p>(三) 其他由理事会或者常务理事会决定的事项。</p>
      <p>第四十一条 本协会的资产，任何单位、个人不得侵占、私分和挪用。</p>
      <p>
        第四十二条
        本协会执行《民间非营利组织会计制度》，依法进行会计核算、建立健全内部会计监督制度，保证会计资料合法、真实、准确、完整。
      </p>
      <p>本协会接受税务、会计主管部门依法实施的税务监督和会计监督。</p>
      <p>
        第四十三条
        协会配备具有专业资格的会计人员。会计不兼任出纳。会计人员调动工作或离职时，必须办清交接手续。
      </p>
      <p>
        第四十四条
        本协会进行换届、变更法定代表人，应当进行财务审计，并将审计报告报送登记管理机关。
      </p>
      <p>
        第四十五条
        本协会按照《协会登记管理条例》规定接受登记管理机关组织的年度检查。
      </p>
      <h4>第六章 章程的修改程序</h4>
      <p>
        第四十六条
        对本协会章程的修改，由理事会表决通过，报登记管理机关预审后，提交会员大会审议。
      </p>
      <p>
        第四十七条
        本协会修改的章程，经会员大会到会会员（代表）2/3以上表决通过后15日内，报登记管理机关核准。登记管理机关核准日期为章程的生效日期。
      </p>
      <h4>第七章 终止和剩余财产处理</h4>
      <p>第四十八条 本协会有以下情形之一，应当终止：</p>
      <p>(一) 完成章程规定的宗旨的；</p>
      <p>(二) 无法按照章程规定的宗旨继续从事公益活动的；</p>
      <p>(三) 发生分立、合并的；</p>
      <p>(四) 自行解散的；</p>
      <p>(五) 其他终止的情形。</p>
      <p>
        第四十九条
        本协会终止，应当由理事会提出终止动议，经会员大会表决通过，并报登记管理机关审查同意。
      </p>
      <p>
        第五十条
        本协会终止前，由理事会确定的人员组成清算组，负责清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。
      </p>
      <p>
        第五十一条
        本协会完成清算工作后，应当向登记管理机关申请办理注销登记手续。
      </p>
      <p>
        第五十二条
        本协会终止后的剩余财产，在登记管理机关的监督下，按照国家有关规定，用于发展与本协会宗旨相关的事业。
      </p>
      <h4>第八章 附则</h4>
      <p>第五十三条 本章程经2019年 06月 30日第 一 次会员大会表决通过。</p>
      <p>第五十四条 本章程的解释权属于本协会理事会。</p>
      <p>第五十五条 本章程自登记管理机关核准之日起生效。</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toInt() {
      this.$router.push("/aboutUs/introduction");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
	.brief-content{
		h4 {
    text-align: center;
  }
	p{
		text-indent: 32px;
	}
	}
  
}
</style>