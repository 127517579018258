import Vue from 'vue'
import Router from 'vue-router'
import router from '@/router'
import routes from './routerConfig.js'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
    //export default new Router({
    //    routes
    //})
export default new Router({
        base: '/',
        mode: 'history', //注释掉默认hash;
        routes
    })
    //返回上一层
    // Vue.prototype.$setgoindex = function() {
    //     if (window.history.length <= 1) {
    //         if (location.href.indexOf('?') === -1) {
    //             window.location.href = location.href + '?goindex=true'
    //         } else if (location.href.indexOf('?') !== -1 && location.href.indexOf('goindex') === -1) {
    //             window.location.href = location.href + '&goindex=true'
    //         }
    //     }
    // }