<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }"
          >专业委员会</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }">财务专业委员会</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }">拟筹建财务专业委员会</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">拟筹建财务专业委员会</div>
      <div class="titname">INTRDUCTION</div>
      <p class="paragraph">
        为解决财务行业人才培养、人才输送等一系列问题，南京秦淮财税金融服务协会拟成立财务专业委员会，以更好的服务财务相关单位，促进企业规范、健康、可持续发展。该委员会成员计划由财务行业顶尖专家学者组成，直属协会理事会管理，致力于研究财务人才的培养机制，突破制约财务行业发展的人才瓶颈，开展公益培训，形成社会化大规模专业培训体系。
      </p>
      <p class="paragraph">
        未来我们将持续扩充专业委员会团队，邀请业界知名专家加入，共同建设财务人才培养体系。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.briefPage {
	h4{
		text-align: center;
	}
	.text{
		text-align: right;
	}
}
</style>