var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-main"},[_c('div',{staticClass:"footTop clearfix"},[_c('div',{staticClass:"footLeft h_left"},[_c('nav',[_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',[_c('a',{staticClass:"feedback",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v("意见反馈")])])])]),_vm._m(5)]),_vm._m(6)]),_c('div',{staticClass:"footBottom"},[_c('p',[_vm._v(" Copyright © From 2020 nanjingfsa.org.cn"),_c('span',[_vm._v("版权所有：南京秦淮财税金融服务协会")]),_c('span',{staticClass:"footBa",on:{"click":_vm.go}},[_vm._v("苏ICP备20005415号")]),_c('img',{staticClass:"filings",attrs:{"src":require("../../assets/images/备案图标.png"),"alt":""}}),_c('span',{staticClass:"footBa",on:{"click":_vm.goS}},[_vm._v("苏公网安备32010402001722号")])])])]),_c('el-dialog',{attrs:{"title":"意见反馈","visible":_vm.dialogVisible,"width":"634px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('p',[_vm._v(" 为了更好的服务,请将您的宝贵意见发送邮箱至:"),_c('span',[_vm._v("nanjingfsa@163.com")]),_vm._v("感谢您的支持！ ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("我知道了")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/index"}},[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/aboutUs/brief"}},[_vm._v("关于协会")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/committee/cpa"}},[_vm._v("专业委员会")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/trends/news"}},[_vm._v("协会动态")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/contactUs"}},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h_logo"},[_c('img',{staticClass:"h_left",attrs:{"src":require("../../assets/images/index/indexLogoDark.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footRight h_right"},[_c('p',[_vm._v("本协会的业务主管单位：南京市秦淮区财政局")]),_c('p',[_vm._v("登记管理机关：南京市秦淮区民政局")]),_c('p',[_vm._v("协会的会员：单位会员、个人会员")]),_c('p',[_vm._v("联系方式：nanjingfsa@163.com")])])
}]

export { render, staticRenderFns }