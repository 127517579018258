<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/aboutUs/organization' }"
          >组织架构</el-breadcrumb-item
        >
        <el-breadcrumb-item>各级机构职能</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">各级机构职能</div>
      <div class="titname">FUNCTION</div>
      <h4>南京财税金融服务协会会员大会</h4>
      <p>
        本协会的最高权利机构是会员大会，每年召开一次。负责制定和修改大会章程，以及选举或者罢免主要负责人等。
      </p>
      <h4>理事会</h4>
      <p>
        理事会是会员大会的执行机构，在会员大会闭会期间领导本协会开展工作，对会员大会负责。理事人数最高不超过会员的30%。负责领导本协会各机构开展工作。
      </p>
      <h4>监事</h4>
      <p>
        本协会设监事1名，监事负责独立监督、检查协会工作开展及财务状况，对会员大会负责。
      </p>

      <h4>注师执业技术研究院</h4>
      <p>
        注师执业技术研究院负责研究注师领域最新政策、业界动向，深入研究未来发展趋势，为注师产业提供专业咨询。
      </p>

      <h4>分会</h4>
      <p>
        设置地区分会，在地区同步开展协会活动，为会员单位提供本地化服务。在专业相匹配的机构开设分会，宣传协会宗旨，发展学生会员，为财税金融产业培养后备人才。
      </p>

      <h4>秘书处</h4>
      <p>
        秘书处协助理事会开展机构日常工作。下设外联部、职业教育部、多元化发展部、国际业务部。
      </p>

      <h4>专业委员会</h4>
      <p>
        组建财务管理、税务管理、法律服务、金融服务、专业顾问、职业技能培训等专业委员会，汇聚行业顶尖专家学者，探讨制约产业发展的瓶颈，为协会提供专业技术支撑。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toInt() {
      this.$router.push("/aboutUs/introduction");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
	h4{
		text-align: center;
	}
}
</style>