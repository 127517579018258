import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/element-ui.less'
import myFooter from './components/quoteCom/myFooter.vue'
import BaiduMap from "vue-baidu-map"
import './animate/animate.css'
Vue.use(BaiduMap, {
    ak: 'luqWhEeEI6FKkvRdKQZDCYzGMLG9T18B'
})
Vue.config.productionTip = false
Vue.component('my-footer', myFooter)

Vue.use(ElementUI);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')