<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >CPA执业圈财总分享会“业财融合（医药行业）”主题活动成功举行</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-11-01</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            CPA执业圈财总分享会“业财融合（医药行业）”主题活动成功举行
          </div>
          <div class="titname">FOUND</div>
          <p>
            2023年10月28日下午，由南京秦淮财税金融服务协会CPA服务联盟、南京财商人生教育科技有限公司、看门狗互联网信息技术（江苏）有限公司联合主办的CPA执业圈财总分享会——“业财融合（医药行业）”主题活动成功举行。本次活动特邀某医药央企江苏分公司的财务总监陆总主讲，看门狗平台的市场部经理Jenny主持，多位会计师事务所审计经理及企业财务高管参与了此次活动。
          </p>
          <div><img src="../../../assets/images/thirteen1.png" alt="" /></div>

          <p>
            活动伊始，主持人Jenny首先代表公司对各位来宾的到来表示热烈的欢迎，接着，她向来宾们介绍了看门狗平台的生态产业链、未来一段时间内将策划和实施的活动规划、以及平台能助力的无限种多元化合作可能。
          </p>
          <div><img src="../../../assets/images/thirteen2.jpg" alt="" /></div>

          <p>
            随后，陆总以医药行业的业财融合为主题，就医药行业政策背景、全景价值链、医药制造领域的业财融合实践及医药流通领域的业财融合实践三大方面进行分享，为大家呈现了一堂生动的医药行业整体运营和未来发展的课程。虽然大多数来宾来自审计行业，但陆总讲授的实践经验具有很强的参考价值。
          </p>
          <div><img src="../../../assets/images/thirteen3.jpg" alt="" /></div>

          <p>
            现场讨论环节气氛积极活跃，陆总就大家提出的SPD+的研发与应用、供应链金融、司库建设、医用小白鼠、业财融合关于财经人员的发展等问题一一进行了解答。
          </p>
          <div><img src="../../../assets/images/thirteen4.jpg" alt="" /></div>

          <p>
            3个小时的主题活动在热烈的气氛中结束，大家都纷纷表示受益匪浅，通过活动不但结交了行业内的精英朋友，也从主讲嘉宾的分享中获得了许多有价值的专业资讯，期待主办方多举办此类活动。
          </p>
          <div><img src="../../../assets/images/thirteen5.jpg" alt="" /></div>
          <div style="text-align: left; border-bottom: 1px solid">
            CPA执业圈
          </div>
          <p>
            “CPA执业圈”主题活动，由南京秦淮财税金融服务协会CPA服务联盟、南京财商人生教育科技有限公司、看门狗互联网信息技术（江苏）有限公司联合主办，旨在通过每月不定期的线下活动，为会计师事务所审计从业人员、各行业的财总等专业人员搭建沟通交流的平台，互相学习、共促发展，实现业务交流与合作。
          </p>
          <p>
            “CPA执业圈”每月活动精彩不断，期待更多的财税法金融领域的专业人士积极参与、共同进步、合作共赢！
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>