<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >协会简介</el-breadcrumb-item
        >
        <el-breadcrumb-item>南京秦淮财税金融服务协会简介</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">南京秦淮财税金融服务协会简介</div>
      <div class="titname">INTRODUCTION</div>
      <p class="paragraph">
        南京秦淮财税金融服务协会是由会计师、税务师、评估师、律师、造价师以及金融证券师等现代服务业专业人员和与之相对应的各类事务所、相关金融机构以及实体企业单位自愿组成的专业性、互助性、服务型非营利性社会组织。
      </p>
      <p class="paragraph">本协会的会员：单位会员、个人会员。</p>
      <h4>协会宗旨</h4>
      <p>
        （一）融合现代服务业的各专业机构及人员，为金融服务于实体经济提供专业服务，同时帮助金融机构防范和化解金融风险；
      </p>
      <p>
        （二）为企业提供人才培训、人才输送及现代管理方案，促进企业规范、健康、可持续发展，提高公司治理水平，完善市场经济体系。
      </p>
      <h4>服务范围</h4>
      <p>
        （一）开展财务、税务、法律和金融等相关专业方面的研究、交流及投融资平台建设（帮助各类处于不同发展阶段的企业提供直接融资的信息与一站式综合解决方案）；
      </p>
      <p>（二）政府委托项目；</p>
      <p>（三）财经系列专业培训；</p>
      <p>（四）建设财经人才培养平台；</p>
      <p>（五）组建财经专业委员会。</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toInt() {
      this.$router.push("/aboutUs/introduction");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
}
</style>