<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >协会简介</el-breadcrumb-item
        >
        <el-breadcrumb-item>协会的愿景</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">协会的愿景</div>
      <div class="titname">vision</div>
      <p class="paragraph">
        架设政府与企业的桥梁，服务企业发展，加强金融机构与实体企业的沟通、互信，促进区域经济发展。
      </p>
      <p class="paragraph" style="margin-top: 30px;">未来一年内我们主要工作规划:</p>
      <p class="paragraph">一、设立“专业委员会”</p>
      <p class="paragraph">
        发挥协会的专业性和互动性的特点，我们拟设立五个专业委员会:
      </p>
      <p class="paragraph">(1)财务管理专业委员会</p>
      <p class="paragraph">(2)税务管理专业委员会</p>
      <p class="paragraph">(3)法律服务专业委员会</p>
      <p class="paragraph">(4)金融服务专业委员会</p>
      <p class="paragraph">(5)相关行业专业顾问委员会</p>
      <p class="paragraph" style="margin-top: 30px;">二、组建“现代企业投融资研究院”</p>
      <p class="paragraph">
        针对不同企业在不同发展时期的发展战略,汇聚各专业机构行业专家的智慧，建立“产学研”一体的应用体系,为企业提供一站式个性化定制的投融资综合解决方案。
      </p>
      <p class="paragraph" style="margin-top: 30px;">三、发起成立“诚信企业投融资联盟"</p>
      <p class="paragraph">
        探索用大数据等现代信息技术为支撑，建立以企业诚信经营为基础的新的银企、融企关系，树立互信的新型投融资关系，解决产融单位之间的信息不对称问题，以实现金融更好的为实体经济服务,特别是提升金融对中小企业的服务水平。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toInt() {
      this.$router.push("/aboutUs/introduction");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
}
</style>