<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footTop clearfix">
        <div class="footLeft h_left">
          <nav>
            <ul>
              <li><a href="/index">首页</a></li>
              <li><a href="/aboutUs/brief">关于协会</a></li>
              <li><a href="/committee/cpa">专业委员会</a></li>
              <li><a href="/trends/news">协会动态</a></li>
              <li><a href="/contactUs">联系我们</a></li>
              <li>
                <a
                  class="feedback"
                  href="javascript:void(0);"
                  @click="dialogVisible = true"
                  >意见反馈</a
                >
              </li>
            </ul>
          </nav>
          <div class="h_logo">
            <img
              src="../../assets/images/index/indexLogoDark.png"
              class="h_left"
            />
          </div>
        </div>
        <div class="footRight h_right">
          <p>本协会的业务主管单位：南京市秦淮区财政局</p>
          <p>登记管理机关：南京市秦淮区民政局</p>
          <p>协会的会员：单位会员、个人会员</p>
          <p>联系方式：nanjingfsa@163.com</p>
        </div>
      </div>
      <div class="footBottom">
        <p>
          Copyright © From 2020 nanjingfsa.org.cn<span
            >版权所有：南京秦淮财税金融服务协会</span
          ><span class="footBa" @click="go">苏ICP备20005415号</span><img class="filings" src="../../assets/images/备案图标.png" alt=""><span class="footBa" @click="goS">苏公网安备32010402001722号</span>
        </p>
      </div>
    </div>
    <el-dialog title="意见反馈" :visible.sync="dialogVisible" width="634px">
      <p>
        为了更好的服务,请将您的宝贵意见发送邮箱至:<span>nanjingfsa@163.com</span>感谢您的支持！
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >我知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods:{
    go(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
    },
    goS(){
      window.open('https://beian.mps.gov.cn/#/query/webSearch','_blank')
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  width: 100%;
  background: #191919;
  .footer-main {
    margin: 0 auto;
    color: #727272;
    padding: 30px 0;
    width: 1200px;
    .footTop {
      height: 160px;
      .h_left {
        float: left;
      }

      .h_right {
        float: right;
      }
      .footLeft {
        li {
          display: inline-block;
          margin-right: 40px;
          a {
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            color: #727272;
            text-decoration: none;
          }
        }
        .h_logo {
          margin-top: 40px;
          color: #727272;
        }
      }
      .footRight {
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          text-align: right;
        }
      }
    }
    .footBottom {
      /* width:480px; */
      line-height: 22px;
      font-size: 14px;
      font-weight: 400;
      /* margin:0 auto; */
      text-align: center;
      .filings{
        margin-left: 15px;
        width: 20px;
        height: 20px;
      }
      p {
        span {
          margin-left: 15px;
          cursor: pointer;
        }
        .footBa:hover{
          color: blue;
        }
      }
    }
  }
  /deep/.el-dialog {
    height: 248px;
    margin-top: 35vh !important;
    border-radius: 4px 4px 4px 4px;
    .el-dialog__header {
      text-align: center;
      .el-dialog__title {
        color: #333333;
      }
      .el-dialog__close {
        font-weight: 900;
      }
    }
    .el-dialog__body {
      padding: 30px 72px;
      color: #333333;
      font-size: 16px;
      line-height: 30px;
      span {
        color: #b38d53;
      }
    }
    .el-dialog__footer {
      text-align: center;
      .el-button {
        width: 119px;
        height: 40px;
        background: #b38d53;
        border-radius: 4px 4px 4px 4px;
        border: none;
      }
    }
  }
}
</style>