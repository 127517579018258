<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }"
          >专业委员会</el-breadcrumb-item
        >
        <el-breadcrumb-item> 法律专业委员会</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-main">
      <div class="main-item">
        <span>组建中</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    
  }
};
</script>

<style lang="less" scoped>
.briefPage {
  
}
</style>