<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item>入会申请</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">南京秦淮财税金融服务协会入会申请</div>
      <div class="titname">MEMBERSHIP APPLICATION</div>
      <h4>协会简介</h4>
      <p>
        南京秦淮财税金融服务协会，英文名称为Financial Services Association
        Nanjing，缩写为Nanjing
        FSA，是由会计师、税务师、评估师、律师、造价师以及金融证券师等现代服务业专业人员和与之相对应的各类中介机构事务所、相关金融机构以及各类实体企业单位自愿组成的
        专业性、互助性服务型非营利性社会组织。
      </p>
      <p>本协会的会员：单位会员、个人会员。</p>
      <h4>协会宗旨</h4>
      <p>
        （一）融合现代服务业的各专业机构及人员，为金融服务于实体经济提供专业服务，同时帮助金融机构防范和化解金融风险；
      </p>
      <p>
        （二）为企业建立现代公司管理制度提供系列方案，促进企业规范、健康、可持续发展，提高公司治理水平，完善市场经济体系。
      </p>
      <h4>会员构成</h4>
      <p>
        本协会是由会计师、税务师、评估师、律师、造价师以及金融证券师等现代服务业专业人员和与之相对应的各类中介机构事务所、相关金融机构以及各类实体企业单位自愿组成的
        专业性、互助性服务型非营利性社会组织。本协会的会员：单位会员、个人会员。
      </p>
      <h4>服务范围</h4>
      <p>
        （一）开展财务、税务、法律和金融等相关专业方面的研究、交流及投融资平台建设（帮助各类处于不同发展阶段的企业提供直接融资的信息与一站式综合解决方案）；
      </p>
      <p>（二）政府委托项目、专业咨询培训与会展；</p>
      <p>（三）促进科技服务创新成果转化；</p>
      <p>（四）参与国内外交流与合作。</p>
      <h4>加入会员</h4>
      <p>
        请填写《南京秦淮财税金融服务协会入会申请书》，并提交给协会会员部。协会将核实您的资格，并进一步提供入会相关材料。经协会理事会批准您的入会申请后，您将正式入会。
      </p>
      <p>
        南京秦淮财税金融服务协会入会申请书，<a
          href="/data/ApplicationForm.docx"
          target="_blank"
          download="入会申请表.docx"
          class="load"
          >请点击下载</a
        >。
      </p>
      <p>联系邮箱： nanjingfsa@163.com</p>
      <!-- <p>电话：4001051108</p> -->
      <h4>入会常见问题解答</h4>
      <p>1、南京秦淮财税金融服务协会的会员类型包括哪些？</p>
      <p>答：本协会会员分为个人会员和单位会员。</p>
      <p>2、申请加入本协会的会员需要具备哪些条件？</p>
      <p>答：申请加入本协会的会员，必须具备下列条件：</p>
      <p>(一)拥护本协会的章程；</p>
      <p>(二)从事的工作须与协会的服务范围相关；</p>
      <p>(三)遵守宪法和法律；</p>
      <p>(四)遵守执业纪律和职业道德规范。</p>
      <p>3、会员入会的程序是什么？</p>
      <p>答：会员入会的程序是：</p>
      <p>(一)提交入会申请书；</p>
      <p>(二)由两名以上会员或一名以上理事推荐；</p>
      <p>(三)经理事会或常务理事会讨论通过；</p>
      <p>(四)颁发会员证并公告；</p>
      <p>4、会员享有哪些权利？</p>
      <p>答：会员享有下列权利：</p>
      <p>(一)本协会的选举权、被选举权和表决权；</p>
      <p>(二)参加本协会的活动；</p>
      <p>(三)优先获得本协会的服务；</p>
      <p>(四)对本协会工作的知情权、建议权和监督权；</p>
      <p>(五)入会自愿、退会自由；</p>
      <p>(六)会员可享有的其他权利。</p>
      <p>5、会员需履行哪些义务？</p>
      <p>答：会员履行下列义务：</p>
      <p>(一)遵守本协会章程；</p>
      <p>(二)执行本协会的决议；</p>
      <p>(三)维护本协会的合法权益；</p>
      <p>(四)按时参加本协会的活动，完成本协会交办的工作；</p>
      <p>(五)向本协会反映情况，提供有关资料；</p>
      <p>(六)按规定缴纳会费；</p>
      <p>(七)会员应履行的其他义务。</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toInt() {
      this.$router.push("/aboutUs/introduction");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
  .brief-content {
    h4 {
      text-align: center;
    }
    p {
      text-indent: 32px;
			a{
				text-decoration: none;
				color: #B38D53;
			}
    }
  }
}
</style>