<template>
  <div class="aboutUsPage">
    <div class="aboutUs-banner">
      <img src="../assets/images/aboutus.png" alt="" />

    </div>
    <div class="aboutUs-main">
      <div class="main-content">
        <el-container>
          <el-aside width="280px">
            <div>
              <p class="aboutTitle">ABOUT US</p>
              <el-menu
                :default-active="defaultActive"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                background-color="#fff"
                text-color="#999999"
                active-text-color="#B38D53"
                router
              >
                <el-menu-item index="/aboutUs/brief">
                  <i class="el-icon-caret-right"></i>
                  <span slot="title">协会简介</span>
                </el-menu-item>
                <el-menu-item index="/aboutUs/organization">
                  <i class="el-icon-caret-right"></i>
                  <span slot="title">组织架构</span>
                </el-menu-item>
                <el-menu-item index="/aboutUs/rules">
                  <i class="el-icon-caret-right"></i>
                  <span slot="title">协会章程</span>
                </el-menu-item>
                <el-menu-item index="/aboutUs/leader">
                  <i class="el-icon-caret-right"></i>
                  <span slot="title">协会领导</span>
                </el-menu-item>
                <el-menu-item index="/aboutUs/apply">
                  <i class="el-icon-caret-right"></i>
                  <span slot="title">入会申请</span>
                </el-menu-item>
              </el-menu>
            </div>
          </el-aside>
          <el-main>
            <router-view></router-view>
          </el-main>
        </el-container>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleOpen() {},
    handleClose() {},
  },
  computed: {
    defaultActive() {
      let pathName = this.$route.name;
      // console.log(this.$route.name);
      if (pathName == pathName) {
        let path = `/aboutUs/${pathName}`;
        return path;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.aboutUsPage {
  width: 100%;
  .aboutUs-banner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .aboutUs-main {
    width: 100%;
    background: #f5f5f5;
    padding: 30px 0;
    .main-content {
      width: 1208px;
      box-sizing: border-box;
      margin: auto;
      background: #ffffff;
      padding: 0 70px;
      /deep/.el-container {
        padding-top: 55px;
        padding-bottom: 55px;
        .el-aside {
          overflow: initial;
          margin-right: 70px;
          .aboutTitle {
            font-size: 20px;
            font-family: Impact;
            font-weight: 400;
            color: #333;
            text-align: center;
            padding-bottom: 15px;
            border-bottom: 2px solid #f5f5f5;
          }
          .el-menu {
            border: none;
            .el-menu-item [class^="el-icon-"] {
                color: #f5f5f5;
                margin-right: 27px;
                margin-left: 45px;
                font-size: 20px;
              }
            .el-menu-item {
              font-size: 16px;
              height: 75px;
              line-height: 70px;
              border-bottom: 2px solid #f5f5f5;
              background: #ffffff !important;
              
            }
            .el-menu-item.is-active {
              border-bottom: 2px solid #b18b53 !important;
              .el-icon-caret-right {
                color: #b18b53 !important;
              }
            }
            .el-menu-item:hover {
              color: #b38d53 !important;
            }
          }
        }
      }
    }
  }
}
</style>