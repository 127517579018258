<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item>聚力赋能，助推梦想启航</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-09-22</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">聚力赋能，助推梦想启航——记秦淮财税协会、财商人生携手为南审大毕业生提供就业指导服务</div>
          <div class="titname">FOUND</div>
          <p>
            金秋九月，丹桂飘香，在这收获的季节里，由南京市人力资源和社会保障局、南京审计大学主办，南京市劳动就业服务管理中心、南京电视台大学生频道共同承办的“宁聚服务行
            乐业金陵”青春就业直通车——2023年毕业生就业指导进校园活动，9月20日下午，走进了南京审计大学。本次活动在南京审计大学2023年秋季校园招聘会现场举办，活动特邀南京秦淮财税金融服务协会和南京财商人生教育科技有限公司为大学生提供现场就业指导、简历诊断等咨询服务。
          </p>
          <div><img src="../../../assets/images/news1.png" alt="" /></div>

          <p>
            本次招聘会共有187家企业现场提供740余个就业岗位，岗位涉及审计、金融、建筑等多个领域，总计求职毕业生达17600余人。
          </p>

          <div><img src="../../../assets/images/new2.png" alt="" /></div>

          <p>
            招聘会现场人头攒动，气氛非常热烈。特别是在就业指导区域，吸引了众多大学生前来咨询求助。据在现场做就业指导的财商人生公司陈宣妍老师介绍，在短短的三个半小时里，她共为100多名大学生提供一对一咨询指导服务，涵盖审计、金融、工程审计、英语、税收等专业，其中以大四学生居多，少部分大三学生前来了解就业情况，寻找实习机会。陈老师就同学们关注的简历制作、行业前景、面试技巧等方面的问题一一做出了精细入微的解答，给学生们带来了不同的体验，大家都反馈受益匪浅、干货满满。
          </p>

          <div><img src="../../../assets/images/news3.png" alt="" /></div>
          <div><img src="../../../assets/images/new4.png" alt="" /></div>
          <div><img src="../../../assets/images/news5.png" alt="" /></div>
          <div><img src="../../../assets/images/news6.png" alt="" /></div>

          <p>
            南京秦淮财税金融服务协会联合南京财商人生教育科技有限公司及相关会计师事务所共同发起成立的注册会计师（CPA）服务联盟，其工作主旨之一是全方位赋能大学生就业，帮助大学生精准把握行业需求，规划职业发展路径，提升就业核心竞争力。为财税法金融相关专业大学生提供职业规划辅导、简历制作指导与修改、面试技巧辅导、简历代投、实习/就业推荐等专业服务。
          </p>

          <p>
            据了解，本次“宁聚服务行
            乐业金陵”青春就业直通车——2023年毕业生就业指导进校园活动后续还将进一步开展。活动预计将在10月份走进南京大学、东南大学、南京航空航天大学等多家南京重点高校。南京秦淮财税金融服务协会、南京财商人生教育科技有限公司也将持续参与此项活动，提升大学生求职能力，助力大学生快速实现就业。
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>