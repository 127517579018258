import home from "../views/home.vue";
import index from "../views/index.vue";
import aboutUs from "../views/aboutUs.vue";
import committee from '../views/committee.vue'
import trends from '../views/trends.vue'
import contactUs from '../views/contactUs.vue'

import brief from '../components/pageCom/about/brief.vue'
import introduction from '../components/pageCom/about/introduction.vue'
import vision from '../components/pageCom/about/vision.vue'
import organization from '../components/pageCom/about/organization.vue'
import framework from '../components/pageCom/about/framework.vue'
import levels from '../components/pageCom/about/levels.vue'
import rules from '../components/pageCom/about/rules.vue'
import leader from '../components/pageCom/about/leader.vue'
import apply from '../components/pageCom/about/apply.vue'
import cpa from '../components/pageCom/committee/cpa.vue'
import resolution from '../components/pageCom/committee/resolution.vue'
import finance from '../components/pageCom/committee/finance.vue'
import proposed from '../components/pageCom/committee/proposed.vue'
import administration from '../components/pageCom/committee/administration.vue'
import law from '../components/pageCom/committee/law.vue'
import metals from '../components/pageCom/committee/metals.vue'
import consultant from '../components/pageCom/committee/consultant.vue'
import skill from '../components/pageCom/committee/skill.vue'
import news from '../components/pageCom/trends/news.vue'
import newOne from '../components/pageCom/trends/newOne.vue'
import newTwo from '../components/pageCom/trends/newTwo.vue'
import newThree from '../components/pageCom/trends/newThree.vue'
import newFour from '../components/pageCom/trends/newFour.vue'
import newFive from '../components/pageCom/trends/newFive.vue'
import newSex from '../components/pageCom/trends/newSex.vue'
import newSeven from '../components/pageCom/trends/newSeven.vue'
import newEnghit from '../components/pageCom/trends/newEnghit.vue'
import interpret from '../components/pageCom/trends/interpret.vue'
import industryDynamics from '../components/pageCom/trends/industryDynamics.vue'
import notice from '../components/pageCom/trends/notice.vue'
import newNine from '../components/pageCom/trends/newNine.vue'
import newTen from '../components/pageCom/trends/newTen.vue'
import newEleven from '../components/pageCom/trends/newEleven.vue'
import newTwelve from '../components/pageCom/trends/newTwelve.vue'
import newThirteen from '../components/pageCom/trends/newThirteen.vue'
import newFourteen from '../components/pageCom/trends/newFourteen.vue'
import newFiveteen from '../components/pageCom/trends/newFiveteen.vue'




let routes = [{
    path: "/",
    name: "home",
    redirect: "/index",
    leaf: true,
    component: home,
    children: [{
            path: "/index",
            name: "index",
            component: index,
        },
        {
            path: "/aboutUs",
            name: "aboutUs",
            component: aboutUs,
            children: [{
                path: "/aboutUs/brief",
                name: "brief",
                component: brief,
            }, {
                path: "/aboutUs/introduction",
                name: "brief",
                component: introduction,
            }, {
                path: "/aboutUs/vision",
                name: "brief",
                component: vision,
            }, {
                path: "/aboutUs/organization",
                name: "organization",
                component: organization,
            }, {
                path: "/aboutUs/framework",
                name: "organization",
                component: framework,
            }, {
                path: "/aboutUs/levels",
                name: "organization",
                component: levels,
            }, {
                path: "/aboutUs/rules",
                name: "rules",
                component: rules,
            }, {
                path: "/aboutUs/leader",
                name: "leader",
                component: leader,
            }, {
                path: "/aboutUs/apply",
                name: "apply",
                component: apply,
            }]
        }, {
            path: "/committee",
            name: "committee",
            component: committee,
            children: [{
                path: "/committee/cpa",
                name: "cpa",
                component: cpa,
            }, {
                path: "/committee/resolution",
                name: "cpa",
                component: resolution,
            }, {
                path: "/committee/finance",
                name: "finance",
                component: finance,
            }, {
                path: "/committee/proposed",
                name: "finance",
                component: proposed,
            }, {
                path: "/committee/administration",
                name: "administration",
                component: administration,
            }, {
                path: "/committee/law",
                name: "law",
                component: law,
            }, {
                path: "/committee/metals",
                name: "metals",
                component: metals,
            }, {
                path: "/committee/consultant",
                name: "consultant",
                component: consultant,
            }, {
                path: "/committee/skill",
                name: "skill",
                component: skill,
            }]
        }, {
            path: "/trends",
            name: "trends",
            component: trends,
            children: [{
                    path: "/trends/news",
                    name: "news",
                    component: news,
                }, {
                    path: "/trends/interpret",
                    name: "interpret",
                    component: interpret,
                },
                {
                    path: "/trends/industryDynamics",
                    name: "interpret",
                    component: industryDynamics,
                },
                {
                    path: "/trends/notice",
                    name: "notice",
                    component: notice,
                },
            ]
        }, {
            path: "/contactUs",
            name: "contactUs",
            component: contactUs,
        }, {
            path: "/newOne",
            name: "news",
            component: newOne,
        }, {
            path: "/newTwo",
            name: "news",
            component: newTwo,
        }, {
            path: "/newThree",
            name: "news",
            component: newThree,
        }, {
            path: "/newFour",
            name: "news",
            component: newFour,
        }, {
            path: "/newFive",
            name: "news",
            component: newFive,
        }, {
            path: "/newSex",
            name: "news",
            component: newSex,
        }, {
            path: "/newSeven",
            name: "news",
            component: newSeven,
        }, {
            path: "/newEnghit",
            name: "news",
            component: newEnghit,
        }, {
            path: "/newNine",
            name: "news",
            component: newNine,
        }, {
            path: "/newTen",
            name: "news",
            component: newTen,
        }, {
            path: "/newEleven",
            name: "news",
            component: newEleven,
        }, {
            path: "/newTwelve",
            name: "news",
            component: newTwelve,
        }, {
            path: "/newThirteen",
            name: "news",
            component: newThirteen,
        }, {
            path: "/newFourteen",
            name: "news",
            component: newFourteen,
        }, {
            path: "/newFiveteen",
            name: "news",
            component: newFiveteen,
        }
    ],
}, ];
export default routes;