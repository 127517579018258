<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >热烈祝贺财经专业大学生实现高质量就业公益巡回讲座（南财专场）取得圆满成功！</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-06-05</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">热烈祝贺财经专业大学生实现高质量就业公益巡回讲座（南财专场）取得圆满成功！</div>
          <div class="titname">FOUND</div>
          <p>
            为更好的服务企业、提高从业人员职业素养，解决财税金融类人才缺乏的问题，特委托南京财商人生教育科技有限公司在协会服务范围内开展公益培训活动。南京秦淮财税金融服务协会负责培训的计划、组织和监督，具体执行由南京财商人生教育科技有限公司负责实施。
          </p>
          <p class="paragraph">
            3月29日晚，由南京财经大学会计学院（以下简称：南财）、南京秦淮财税金融服务协会（以下简称协会）主办，南京财商人生教育科技有限公司（以下简称：财商人生）协办的财经专业大学生实现高质量就业公益巡回讲座（南财专场）在南财博观楼301教室成功举办。南京秦淮财税金融服务协会会长、大华会计师事务所合伙人、南京财商人生教育科技有限公司创始人方维翔先生及会计师事务所合伙人助理陈新宇女士分别就如何提升职业能力，开启高质量就业之门及会计师事务所方向就业规划向同学们作了主题分享。南财会计学院党委书记卜金涛、学工办主任仲小晶及俞伟老师参加了本次讲座。
          </p>
					<div><img src="../../../assets/images/fqPhoto.png" alt="" /></div>
          
          <p class="paragraph">
            讲座由会计学院俞伟老师主持，卜金涛书记作开场致辞。卜书记对协会及财商人生各位来访的嘉宾们表示了极大的欢迎，并表示本次前来参加活动的学生都非常积极，除了许多面临实习就业的大三同学，也有很多大二大一的同学。
          </p>
					<div> <img src="../../../assets/images/fqPhoto2.png" alt="" /></div>
         

          <p class="paragraph">
            卜书记说：“可以看出，学生们对于未来的就业和职业发展还是很关心的，这是一个好现象”然后对今天的两位主讲嘉宾:方维翔先生和陈新宇女士做了详细的介绍。
          </p>
          <p class="paragraph">
            陈老师作为第一位主讲人，对会计师事务的工作内容，职业晋升路径和未来职业前景进行了详细的介绍。还特别指出了学生们较为关心的薪资问题，对不同阶段，不同级别的事务所从业人员的薪资进行了简单的剖析。
          </p>
					<div><img src="../../../assets/images/fqPhoto3.png" alt="" /></div>
          
          <p class="paragraph">
            同时还特别举出了自己在项目上的实际经验，用最“接地气”的方式，让同学们多方面了解会计师事务所。
          </p>
					<div> <img src="../../../assets/images/fqPhoto4.png" alt="" /></div>
         
          <p class="paragraph">
            方维翔先生则从会计师事务所的行业特性，国家政策走向及市场趋势等几个宏观方面进行了简单的介绍。
          </p>
          <p>
            并特别站在大学生的角度，提出了个人职业能力的重要性，方维翔先生明确表示“南京财经大学对学生的人才培养和职业技能提升方面是具有前瞻性的，与我们财商人生的宗旨不谋而合”
          </p>
          <p class="paragraph">
            并特别提到国家十四五规划，未来将会全面开启高素质、专业化、复合型、国际化会计人才培训培养，相信我们南财会计学院的学生一定大有可为！
          </p>
					<div><img src="../../../assets/images/fqPhoto5.png" alt="" /></div>
          
          <p class="paragraph">
            “加强大学生就业指导和岗前培训，让更多高校毕业生提升职业能力，在就业时少走弯路，规避误区，从而真正实现高质量就业，一直是我们的目标。”方维翔先生在最后说道。
          </p>
					<div><img src="../../../assets/images/fqPhoto6.png" alt="" /></div>
          
          <p class="paragraph">
            最后的学生提问环节，学生们也是积极举手发言，有些同学希望了解经济大环境不好，事务所会不会受到影响。有的大二的同学则想了解进入事务所实习的渠道，还有的备考CPA的同学想了解事务所对证书的看法等等。方维翔先生都对此进行了详细的解答。
          </p>
					<div><img src="../../../assets/images/fqPhoto7.png" alt="" /></div>
          
          <p class="paragraph">
            南京秦淮财税金融服务协会成立于2019年6月，工作主旨是为会计师事务所、律师事务所等，以及金融机构输送与岗位适配的人才；提供专业培训，加速人才成长，解决人才结构空心化问题，为财税金融产业赋能。
          </p>
          <p class="paragraph">
            本次公益讲座旨在发挥协会平台桥梁纽带作用，携同企业为大学生搭建高质量就业平台，切实推动事务所、企业和高校有效对接。通过讲座相信南京财经大学参会的同学们也对会计事务所有了更为深入的认识，对未来的规划也愈发明朗。与此同时，来访的嘉宾们也肯定了同学们的积极态度与专业知识储备。在这里衷心希望同学们可以收获满满，未来可以如愿进入理想岗位！
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>