<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item>协会简介</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-main">
      <div class="main-item" @click="toInt">
        <span>南京秦淮财税金融服务协会简介</span><span>2019-12-09</span>
      </div>
      <div class="main-item" @click="toVis">
        <span>协会的愿景</span><span>2019-12-10</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    toInt(){
      this.$router.push('/aboutUs/introduction')
    },
    toVis(){
      this.$router.push('/aboutUs/vision')
    }
  }
};
</script>

<style lang="less" scoped>
.briefPage {
  
}
</style>