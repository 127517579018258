<template>
  <div class="contactUsPage">
    <div class="contactUs-Banner">
      <img src="../assets/images/contactus.png" alt="" />
    </div>
    <div class="contactUs-main">
      <div class="map-content">
        <div class="title">联系我们</div>
        <div class="titname">CONTACT US</div>
				<div class="tip"><img src="../assets/images/icon.png" alt=""><span>地址：南工院金蝶大学科技园（秦淮区中山东路532-2号A1座3楼）</span></div>
        <baidu-map
          class="map"
          :center="{ lat: 32.044522, lng: 118.829301 }"
          :zoom="17"
					:scroll-wheel-zoom="true"
        >
          <bm-marker
            :position="{ lat: 32.044522, lng: 118.829301 }"
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
          >
          </bm-marker>
          <bm-info-window
            :position="{ lat: 32.044522, lng: 118.829301 }"
            :width="250"
            :height="70"
            :show="infoWindow.show"
            @close="infoWindowClose"
            @open="infoWindowOpen"
          >
            <p v-text="infoWindow.contents"></p>
          </bm-info-window>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        </baidu-map>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {
      infoWindow: {
        show: true,
        contents:
          "南工院金蝶大学科技园，地址：（秦淮区中山东路532-2号A1座3楼）",
      },
    };
  },
	
  methods: {
    infoWindowClose(e) {
      this.infoWindow.show = false;
    },
    infoWindowOpen(e) {
      this.infoWindow.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
.contactUsPage {
  width: 100%;
  .contactUs-Banner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .contactUs-main {
    width: 100%;
    background: #f5f5f5;
    padding: 30px 0;
    .map-content {
      width: 1200px;
      margin: auto;
      padding: 65px 0;
      background: #fff;
      .title {
        text-align: center;
        font-size: 23px;
        font-weight: 400;
      }
      .titname {
        margin-top: 15px;
        padding-bottom: 60px;
        text-align: center;
        font-size: 14px;
        color: #999999;
      }
			.tip{
				 width: 1050px;
				margin: auto;
				padding-bottom: 30px;
				img{
					margin-right: 20px;
					vertical-align: middle;
				}
			}
      .map {
        width: 1050px;
				margin: auto;
        height: 480px;

        p {
          width: 255px;
        }
				/deep/ bm-panorama{
					margin-right: 80px;
				}
      }
    }
  }
}
</style>

</style>