<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >【喜讯】南京秦淮财税金融服务协会荣获“AAA等级社会组织”荣誉称号</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2024-01-25</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
           【喜讯】南京秦淮财税金融服务协会荣获“AAA等级社会组织”荣誉称号
          </div>
          <div class="titname">FOUND</div>
          <p>
            2024年1月24日上午，秦淮区社会组织等级评估授牌和颁证仪式在秦淮区社会组织培育发展中心举行。经过社会组织申报、第三方评估机构组织评估专家进行现场评估、评估委员会审定、公示、复核等程序,南京秦淮财税金融服务协会获评AAA级社会组织。
          </p>
          <div><img src="../../../assets/images/fiveteen1.png" alt="" /></div>
          <div>
						<img src="../../../assets/images/fiveteen2.png" alt="" />
					</div>

          <p>
            协会将以此次获得社会组织等级评估3A级荣誉为契机,优化管理和服务,积极改进,争取向4A、5A级荣誉迈进!同时，协会还将进一步提升内部治理水平，团结协会全体会员发挥专业优势作用，引领行业发展，通过财税法金融服务赋能社会经济高质量发展。
          </p>
        
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>