<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >南京秦淮财税金融服务协会正式成立</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
				<div class="tipTime">发表时间：2019-06-30</div>
        <div class="brief-content" style="font-size:16px">
          <div class="title">南京秦淮财税金融服务协会正式成立</div>
          <div class="titname">FOUND</div>
          <p class="paragraph">
           2019年6月30日，南京秦淮财税金融服务协会宣布成立！
          </p>
          
        </div>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
		background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
		width: 100%;
		background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
			width: 1200px;
			margin: auto;
			background: #fff;
			padding: 50px 40px;
      .breadcrumb {
        h4 {
          text-align: center;
        }
        .text {
          text-align: right;
        }
      }
    }
  }
}
</style>