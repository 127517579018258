<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >南京秦淮财税金融服务协会方维翔会长、武孝奎秘书长应邀赴江苏省温州商会参观交流</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-10-25</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            南京秦淮财税金融服务协会方维翔会长、武孝奎秘书长应邀赴江苏省温州商会参观交流
          </div>
          <div class="titname">FOUND</div>
          <div><img src="../../../assets/images/twelve.png" alt="" /></div>
          <p>
            10月13日上午，南京秦淮财税金融服务协会方维翔会长、武孝奎秘书长赴江苏省温州商会参观交流，张国利会长等江苏省温州商会领导热情接待，同时一起参访的还有南京安徽商会、南京江西商会的有关领导。
          </p>

          <p>
            张会长首先代表江苏省温州商会对南京秦淮财税金融服务协会、南京安徽商会、南京江西商会领导的到来表示热烈欢迎，张会长说，江苏省温州商会作为异地商会，具有很强的凝聚力，在商会建设过程中，商会依据“四同”的工作原则，充分发挥大家的积极性，想会员所想，做会员所需，极大地提升了商会的凝聚力和影响力。张会长就商会的发展历史、组织架构、工作机制、服务项目、公益慈善、制度性工作等向何会长作了交流，同时希望今后与南京秦淮财税金融服务协会、南京安徽商会、江西商会多沟通多学习，携手共进，共同发展。
          </p>
					<p>接着，南京秦淮财税金融服务协会方维翔会长介绍了协会的基本情况，方会长说，南京秦淮财税金融服务协会是一家为会计师、律师、评估、造价、税务等事务所以及金融机构、企业输送人才、提供职业技能培训，打造多元化业务合作的平台。通过平台上全国范围的会计师、税务师、评估师、造价师、律师、金融证券人士在业务、技术、人才等方面的跨界融合，从而为实体企业提供全过程咨询服务，在经营管理、投融资战略、企业内训等诸多方面，以专业化、差异化的特色，数字化的手段，全面赋能实体经济和资本市场。</p>

          <p>
            张会长在听取了方会长的介绍后，饶有兴趣地说，方会长协会的平台资源汇集了企业不同发展阶段需要的各种服务的支持，江苏省温州商会旗下共有700多家会员单位，在日常的走访和交流中，我们也了解到不少企业有财税法方面的培训、咨询、投融资需求，且目前我们金弘基产业发展有限公司承建开发的麒麟长三角企业总部基地正在招商，入驻企业也会有同样的需求，所以江苏省温州商会、南京秦淮财税金融服务协会可通力合作，发挥桥梁纽带作用，会员信息共享，在财税法金融领域为会员企业提供专业、优质、高效的深层次高端服务，助力会员企业高质量发展。
          </p>
          <p>
            方会长对张会长的一番话高度认可，他说，江苏省温州商会的会建工作是全国商会的标杆，值得我们协会学习，我会将以合作促共赢，以交流促发展的宗旨，学习借鉴江苏省温州商会在组织活动、管理运营等方面的经验和方法。通过专业资源优势赋能各级商会、行业协会更好地为其会员企业提供增值服务。
          </p>
					<p>大家畅所欲言，热情交流，为今后进一步合作与发展奠定了良好的基础。</p>
         
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>