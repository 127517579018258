<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >关于会费标准的公告</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-07-03</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            关于会费标准的公告
          </div>
          <div class="titname">FOUND</div>
                    <p>各会员单位：</p>
                    <p class="paragraph"> 根据本协会2019年6月30日召开的代表大会相关内容，采取无记名投票形式，通过了本协会会费的收费标准如下：</p>
                    <p style="padding-left: 60px;">1、会长（副会长）单位：1000元/年</p>
                    <p style="padding-left: 60px;">2、秘书长（副秘书长）单位：1000元/年</p>
                    <p style="padding-left: 60px;">3、理事（常务理事）单位：1000元/年</p>
                    <p style="padding-left: 60px;">4、会员单位：1000元/年</p>
										<p>特此公告！</p>
                    <div style="margin-top: 30px;" class="footerText">南京秦淮财税金融服务协会</div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        
				.footerText{
					text-align: right;
				}
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>