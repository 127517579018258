<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }"
          >专业委员会</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }">人才开发研究院</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }">CPA人才学院</el-breadcrumb-item>
        <el-breadcrumb-item>关于同意成立“南京秦淮财税金融服务协会人才...</el-breadcrumb-item>

      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">关于同意成立“南京秦淮财税金融服务协会人才开发研究院”的决议</div>
      <div class="titname">FOUND</div>
      <p style="margin-bottom: 30px;">
       各有关单位：
      </p>
      <p class="paragraph" style="margin-bottom: 30px;">
        根据协会事业发展需要，按照章程要求，经南京秦淮财税金融服务协会第一届常务理事会第六次会议审议，与全国事务所同仁联合共建，同意成立南京秦淮财税金融服务协会人才开发研究院；及下属单位CPA人才学院。
      </p>
      <p style="margin-bottom: 30px;">另：与全国律师事务所同仁联合共建，还将成立法律人才学院。</p>
			<div class="text">南京秦淮财税金融服务协会</div>
			<div style="margin-bottom: 30px;" class="text"> 二0二三年四月十二日</div>
      <img src="../../../assets/images/nanjing.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.briefPage {
	h4{
		text-align: center;
	}
	.text{
		text-align: right;
	}
}
</style>