<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >秦淮财税金融服务协会、财商人生就业指导进高校，为学子提供就业助力</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-10-24</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            秦淮财税金融服务协会、财商人生就业指导进高校，为学子提供就业助力
          </div>
          <div class="titname">FOUND</div>
          <div><img src="../../../assets/images/eleven1.png" alt="" /></div>
          <p>
            “金九银十”求职季，南京各大高校2024届毕业生秋季双选会拉开序幕。9、10两月，由南京市人力资源和社会保障局主办，南京市劳动就业服务管理中心、南京电视台大学生频道共同承办的“宁聚服务行
            乐业金陵”青春就业直通车——2023年毕业生就业指导进校园活动，特邀南京秦淮财税金融服务协会和南京财商人生教育科技有限公司携手分别为南京审计大学、南京财经大学、南京航空航天大学的学子们提供现场就业指导、简历诊断等服务。
          </p>
          <div><img src="../../../assets/images/eleven2.png" alt="" /></div>
          <div><img src="../../../assets/images/eleven3.png" alt="" /></div>
          <div><img src="../../../assets/images/eleven4.png" alt="" /></div>

          <p>
            从三所高校的招聘会现场情况来看，2024届毕业生求职意愿比往年都强烈，参加人数均多以万计，职业规划也更加明确。
          </p>
          <div><img src="../../../assets/images/eleven5.png" alt="" /></div>

          <p>
            在活动现场，秦淮财税金融服务协会、财商人生的就业指导老师结合三所高校毕业生的专业特点，从当前的就业形势入手，给毕业生们提供了一些择业建议：
          </p>
          <p>
            首先要了解自己选择的行业发展趋势，是否处于快速增长期，未来的发展方向和潜力如何；其次要考虑个人的兴趣以及所具备的技能和就业岗位的匹配度，因为兴趣可以激发对工作的热情；最后要去全面的了解自己心仪的企业，对自己的职业做好规划。大学阶段除了在校内汲取理论知识外，建议同学们多参加社会实践活动、关注跟自己未来职业相关的实操技能的学习，为走上工作岗位做好充分准备，机会是留给有准备的人。
          </p>
          <div><img src="../../../assets/images/eleven6.png" alt="" /></div>

          <p>
            从同学们的简历制作来看，整体质量不错，但普遍存在的问题是填写的内容过于冗杂，排版不是特别清晰，甚至有些学生的模块多至10个。就业指导老师就这些问题都一一进行了指导。同学们都反馈受益匪浅、干货满满。
          </p>

          <div><img src="../../../assets/images/eleven7.png" alt="" /></div>

          <p>
            南京秦淮财税金融服务协会联合南京财商人生教育科技有限公司及相关会计师事务所共同发起成立的注册会计师（CPA）服务联盟，其工作主旨之一是全方位赋能大学生就业，帮助大学生精准把握行业需求，规划职业发展路径，提升就业核心竞争力。为财税法金融相关专业大学生提供职业规划辅导、简历制作指导与修改、面试技巧辅导、简历代投、实习/就业推荐等专业服务。
          </p>
          <div><img src="../../../assets/images/eleven8.png" alt="" /></div>
          <p>
            秦淮财税金融服务协会、财商人生的就业指导活动还将持续走进各大高校，通过主题讲座、就业训练营等相关服务为大学生搭建就业桥梁，让学生少走弯路、掌握一定技能，提升大学生就业能力。
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>