<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/aboutUs/brief' }"
          >关于协会</el-breadcrumb-item
        >
        <el-breadcrumb-item>协会领导</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-content">
      <div class="title">南京秦淮财税金融服务协会领导</div>
      <div class="titname">LEADER</div>
      <h4>本协会主要负责人名单如下：</h4>
			<p>会长：方维翔</p>
			<p>副会长：吴成 岳修奎</p>
			<p>秘书长兼副会长：武孝奎</p>
			<p>监事：严震</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toInt() {
      this.$router.push("/aboutUs/introduction");
    },
  },
};
</script>

<style lang="less" scoped>
.briefPage {
	.brief-content{
		h4 {
    text-align: center;
  }
	}
  
}
</style>