<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >协会委托财商人生开展财经公益培训</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2021-10-18</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">协会委托财商人生开展财经公益培训</div>
          <div class="titname">FOUND</div>
          <p class="paragraph">为更好的服务企业、提高从业人员职业素养，解决财税金融类人才缺乏的问题，特委托南京财商人生教育科技有限公司在协会服务范围内开展公益培训活动。南京秦淮财税金融服务协会负责培训的计划、组织和监督，具体执行由南京财商人生教育科技有限公司负责实施。</p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
				div{
					text-align: center;
					margin: 15px 0;
					img{
					width: 80%;
				}
				}
				
      }
    }
  }
}
</style>