<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >财经金融专业大学生实现高质量就业公益巡回讲座（南农大专场）成功举办</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-06-06</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            财经金融专业大学生实现高质量就业公益巡回讲座（南农大专场）成功举办
          </div>
          <div class="titname">FOUND</div>
          <p>
            4月26日下午，由南京农业大学金融学院（以下简称：南农大）、南京秦淮财税金融服务协会（以下简称协会）主办，南京财商人生教育科技有限公司（以下简称：财商人生）协办的财经金融专业大学生实现高质量就业公益巡回讲座（南农大专场）在南农大第三实验楼1306报告厅成功举办。
          </p>
					<div><img src="../../../assets/images/fq2Photo1.png" alt="" /></div>
          
          <p>
            南京秦淮财税金融服务协会会长、大华会计师事务所合伙人、南京财商人生教育科技有限公司创始人方维翔先生及会计师事务所合伙人助理陈新宇女士分别就如何提升职业能力，开启高质量就业之门及会计师事务所方向就业规划向同学们作了主题分享。讲座由协会朱静琳副秘书长主持，金融学院陆老师作开场致辞。
          </p>
          <p>
            陆老师对方老师及协会、财商人生各位嘉宾的到来表示热烈的欢迎，他说，方老师是我们金融学院硕导，今天很荣幸能邀请到方老师回家给同学们作财经金融专业大学生实现高质量就业主题分享，相信对同学们未来职业规划及对会计师事务所的认识方面一定会有很大的帮助。
          </p>
					<div> <img src="../../../assets/images/fq2Photo2.png" alt="" /></div>
         
          <p>
            陈老师作为第一位主讲人，先简单的和同学们做了一个互动，发现在座的同学对会计师事务所的了解甚少。
          </p>
          <p>
            接着，她就会计师事务所的工作内容、职业晋升路径及会计师事务所的职业发展方向等进行了详细阐述。
          </p>
<div><img src="../../../assets/images/fq2Photo3.png" alt="" /></div>
          
          <p>
            关于许多金融专业的同学心系券商、投行等机构的现象，陈老师明确指出：“选择去会计师事务所可以获得高质量就业的机会，是你未来职业生涯很好的跳板，在会计师事务所从业5年以上的注册会计师是券商和投行非常抢手的人才。”
          </p>
					<div><img src="../../../assets/images/fq2Photo4.png" alt="" /></div>
          
          <p>
            接着方老师则以“高质量就业”为切入点，对现在大学生就业现状及国家政策走向等几个宏观问题进行了简单的叙述。
          </p>
          <p>
            他对2022年12月26日，中注协修订印发的《中国注册会计师行业人才胜任能力指南》中对会计师事务所助理人员的胜任能力作了详细解读，从中可看出，会计师事务所对从业人员的综合能力要求特别高，非常能锻炼人，是培养专业人才的黄埔军校。
          </p>
          <p>
            他说，“综观知名公司的财务高管、金融机构的高管，70%-80%都有在会计师事务所的从业经历，我本人也是受益于会计师事务所。”
          </p>
					<div><img src="../../../assets/images/fq2Photo5.png" alt="" /></div>
          
          <p>
            最后，方老师特别提到财政部发布《会计改革与发展“十四五”规划纲要》的通知，明确指出注会行业发展需求将再次迎来一波大的增长，截至2025年，注册会计师行业从业人员数量为40万人，注册会计师行业年收入规模1900亿元！
          </p>
          <p>
            这也就意味着，当前注册会计师行业从业人数数量的缺口依然很大，但对从业人员的要求也非常高，希望南农大优秀的财经金融专业大学生可以加入到我们这个行业中来。
          </p>
					<div> <img src="../../../assets/images/fq2Photo6.png" alt="" /></div>
         
          <p>
            此外，本次讲座也设置了互动问答环节，让同学们自由发言、互相交流。有位研一的同学希望了解会计师事务所是否有助于之后跳槽到券商等机构；有些大二的同学则想了解进入事务所暑期实习的渠道等等，方老师都对此进行了详细解答。
          </p>
          <p>
            同时，学生们也表示，将进一步加强对相关技能知识的学习，不断提升自己的专业素养，保持积极向上的心态，才能在未来的职场中获得更好的就业机会。
          </p>
					<div><img src="../../../assets/images/fq2Photo7.png" alt="" /></div>
          
          <p>
            讲座的最后朱副秘书长进行总结，并对南京秦淮财税金融服务协会进行了简单的介绍。
          </p>
          <p>
            协会的主旨就是为会计师事务所、律师事务所等，以及金融机构输送与岗位适配的人才；提供专业培训，加速人才成长，解决人才结构空心化问题，为财税金融产业赋能。
          </p>
					<div><img src="../../../assets/images/fq2Photo8.png" alt="" /></div>
          
          <p>
            本次公益讲座旨在发挥协会平台桥梁纽带作用，携同企业为大学生搭建高质量就业平台，切实推动事务所、企业和高校有效对接。
          </p>
          <p>
            在这里衷心希望南农大的同学们可以收获满满，未来可以如愿开启高质量就业之路！
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
				p{
					text-indent: 32px;
				}
        div {
          text-align: center;
margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>