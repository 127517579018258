<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >2019年秦淮区重点企业暨中小企业培训会议召开</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2019-12-20</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">2019年秦淮区重点企业暨中小企业培训会议召开</div>
          <div class="titname">CONVENE</div>
          <p>12月20日，2019南京秦淮财税金融服务协会第一次会议在宁召开。</p>
          <p>
            本次会议主题为“秦淮区重点企业暨中小企业培训”即承接政府部门的委托组织秦淮区重点企业相关专业人员对财务、税务、法律和金融等相关专业方面的政策、问题进行研究、交流及研讨，并提供综合解决方案、及相关专业知识的培训。
          </p>
					<div><img src="../../../assets/images/meeting1.png" alt="" /></div>
          <p>
            本次会议由南京秦淮财税金融服务协会及南京秦淮区财政局联盟共同举办。
          </p>
          <p>
            南京秦淮财税金融服务协会会长、南京市政协委员、秦淮区人大常委、大华会计师事务所合伙人方维翔，财政局副局长蒋静娟，科技局专家杨云峰，税务局专家卢识、吴晓翔，南京财经大学教授顾晓明，江苏泛华事务所主任律师耿华，国开行金融科技研究院负责人周天喜，南京证券董事仝金刚，财政局专家吴琪等出席培训会议。
          </p>

          <p>培训时间：2019年12月20日（周五）全天。</p>

          <p>
            参加对象：全区400家重点企业暨100家中小企业代表、区财税办全体人员。
          </p>
          <div><img src="../../../assets/images/meeting2.png" alt="" /></div>
          <p>
            期间，南京秦淮财税金融服务协会方维翔会长发言中指出当前协会的五个重点项目：
          </p>
          <p>（一） 政府委托项目调研及培训：</p>
          <p>
            承接政府部门的委托组织相关专业人员对财务、税务、法律和金融等相关专业方面的政策、问题进行研究、交流及研讨，并提供综合解决方案。接受委托对各类机构（企业）的人员及所属工作人员进行财务、税务、法律和金融等相关专业知识的培训；
          </p>
          <p>（二） 专业综合咨询：</p>
          <p>
            针对各类企业经营中出现的问题，组织财务、税务、法律、金融和相关行业专家等专业机构和个人提供专业的综合咨询服务。
          </p>
          <p>（三） 投融资平台建设：</p>
          <p>
            秉承诚实互信原则，基于协会成员专业性，架设辖区内各类企业与金融机构沟通桥梁，构建辖区投融资平台。具体如下：
          </p>
          <p>
            我们与各类商业银行已建立信息诚信互通，帮助企业（特别是中小微企业）办理信用贷款等项目，实现用企业信用代替抵押物的间接融资的模式创新；
          </p>
          <p>
            我们与各类基金及其他长期投资机构已建立信息数据分析机制，为各类处于不同发展阶段的企业提供直接融资的信息与一站式综合解决方案。特别是对于孵化后（即VC后期）的企业，我们可以提供针对企业实际情况，配备对应行业的财税法金融专家团队做融资辅导的个性化定制方案，即全程“保姆式”服务。
          </p>
          <p>（四） 促进区内企业转型升级：</p>
          <p>
            参与国内外经济交流与合作，建立“产学研”一体的应用体系。以提升区域各类企业转型升级为目标，积极提供科技创新成果信息，并协助其科技创新成果转化工作。
          </p>
          <p>（五） 协助企业对外招才引智：</p>
          <p>
            未来企业间竞争最主要的是人才的竞争，我们利用参与国内外经济交流与合作机制，积极搭建各类企业人才合作信息平台，特别是为区内企业招聘人才引入智囊提供信息和窗口。同时，更为各类人才提供充分发挥能力舞台。
          </p>
          <div><img src="../../../assets/images/meeting3.png" alt="" /></div>
          <p>及未来一年内我们主要工作规划：</p>
          <p>一、设立“专业委员会”</p>
          <p>发挥协会的专业性和互动性的特点，我们拟设立五个专业委员会：</p>
          <p>（1）财务管理专业委员会</p>
          <p>（2）税务管理专业委员会</p>
          <p>（3）法律服务专业委员会</p>
          <p>（4）金融服务专业委员会</p>
          <p>（5）相关行业专业顾问委员会</p>
          <p>二、组建“现代企业投融资研究院”</p>
          <p>
            针对不同企业在不同发展时期的发展战略，汇聚各专业机构行业专家的智慧，建立“产学研”一体的应用体系，为企业提供一站式个性化定制的投融资综合解决方案。
          </p>
          <p>三、发起成立“诚信企业投融资联盟”</p>
          <p>
            探索用大数据等现代信息技术为支撑，建立以企业诚信经营为基础的新的银企、融企关系，树立互信的新型投融资关系，解决产融单位之间的信息不对称问题，以实现金融更好的为实体经济服务，特别是提升金融对中小企业的服务水平。
          </p>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
				div{
					text-align: center;
					margin: 15px 0;+
					img{
					width: 80%;
				}
				}
				
      }
    }
  }
}
</style>