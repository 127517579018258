<template>
  <div class="briefPage">
    <div class="breadcrumb">
      <div class="tip">您当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }"
          >专业委员会</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/committee/cpa' }">人才开发研究院</el-breadcrumb-item>
        <el-breadcrumb-item>CPA人才学院</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="brief-main">
      <div class="main-item" @click="toInt">
        <span>关于同意成立“南京秦淮财税金融服务协会人才开发研究院”的决议</span><span>2023-06-13</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    toInt(){
      this.$router.push('/committee/resolution')
    },
  }
};
</script>

<style lang="less" scoped>
.briefPage {
  
}
</style>