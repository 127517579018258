<template>
  <div class="briefPage">
    <div class="newBanner">
      <img src="../../../assets/images/dynamics.png" alt="" />
    </div>
    <div class="breadBack">
      <div class="breadMain">
        <div class="breadcrumb">
          <div class="tip">您当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会动态</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/trends/news' }"
              >协会新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item
              >关于同意成立“南京秦淮财税金融服务协会人才开发研究院”的决议</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="tipTime">发表时间：2023-06-13</div>
        <div class="brief-content" style="font-size: 16px">
          <div class="title">
            关于同意成立“南京秦淮财税金融服务协会人才开发研究院”的决议
          </div>
          <div class="titname">FOUND</div>
                    <p>各有关单位：</p>
                    <p> 根据协会事业发展需要，按照章程要求，经南京秦淮财税金融服务协会第一届常务理事会第六次会议审议，与全国事务所同仁联合共建，同意成立南京秦淮财税金融服务协会人才开发研究院；及下属单位CPA人才学院。</p>
                    <p>另：与全国律师事务所同仁联合共建，还将成立法律人才学院。</p>
                    <div style="margin-top: 30px;" class="footerText">南京秦淮财税金融服务协会</div>
                    <div class="footerText">二0二三年四月十二日</div>
                <div>
                    <img src="../../../assets/images/nanjing.jpg" alt="">
                </div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.briefPage {
  .newBanner {
    background: #f5f5f5;
    img {
      width: 100%;
    }
  }
  .breadBack {
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0;
    .breadMain {
      width: 1200px;
      margin: auto;
      background: #fff;
      padding: 50px 40px;
      .brief-content {
        p {
          text-indent: 32px;
        }
				.footerText{
					text-align: right;
				}
        div {
          text-align: center;
          margin: 15px 0;
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>